import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';
import { ReqSpmt10311Init } from 'src/app/request/req-spmt10311-init';
import { ReqSpmt10311Search } from 'src/app/request/req-spmt10311-search';
import { DropdownListDto, RspSpmt10311Init } from 'src/app/response/rsp-spmt10311-init'
import { Spmt10311Dto, RspSpmt10311Search } from 'src/app/response/rsp-spmt10311-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Spmt10311DetailDialogComponent, DTRDetailDto } from 'src/app/dialog/spmt10311-detail-dialog/spmt10311-detail-dialog.component';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';
import { ErrorNotificationDialogComponent } from 'src/app/dialog/error-notification-dialog/error-notification-dialog.component';
import { Spmt10311SlipNoListDialogComponent } from 'src/app/dialog/spmt10311-slipno-list-dialog/spmt10311-slipno-list-dialog.component';
import { ReqSpmt10311Delete } from 'src/app/request/req-spmt10311-delete';
import { NotificationDialogComponent } from 'src/app/dialog/notification-dialog/notification-dialog.component';
import { Spmt10311HeaderInfo, ReqSpmt10311Confirm } from 'src/app/request/req-spmt10311-confirm';
import { ReqSpmt10311GetDetail } from 'src/app/request/req-spmt10311-get-detail';
import { ReqSpmt10311Exclusion } from 'src/app/request/req-spmt10311-exclusion';
import { Spmt10311GetDetailDto, RspSpmt10311GetDetail } from 'src/app/response/rsp-spmt10311-get-detail';
import { RspUserInfo, UserInfoDto } from 'src/app/response/rsp-user-info';
import { ReqUserInfoSearch } from 'src/app/request/req-user-info-search';
import { RspGetStoreList, GetStoreListDto } from 'src/app/response/rsp-get-store-list';
import { ReqGetStoreList } from 'src/app/request/req-get-store-list';
import { ReqAccess } from 'src/app/request/req-access';
import { ReqSpmt10311CheckBeforeSearch } from 'src/app/request/req-spmt10311-check-before-search';
import { Rsp } from 'src/app/response/rsp';
import { ActivatedRoute } from '@angular/router';
import { ME100010, ME200001, ME200003, ME200004, ME200005, ME200007, ME200016, MI100001, MI200001, MI200002, MI200003, MQ200002, MQ200006, MQ200010 } from "src/app/common/const-message-id";
import { MessageService } from 'src/app/service/message.service';
import { ReqPageDto } from 'src/app/request/req-page-dto';

export class ItemSelect {
  check: FormControl;
  slipKbnCtrl: FormControl;
  dtQtyCtrl: FormControl;
  dtlCommentCtrl: FormControl;
  result: Spmt10311Dto;
}

export interface AccessInfo extends ReqAccess {
  cmpnyCd?: string;
  lang?: string;
  sysModeCd?: string;
}

interface Spmt10311TableColumnDef extends TableColumnDef {
  type: string;
}

class Spmt10311Constant {
  public static readonly MAXITEMS: number = 1000;
  public static readonly MOVESTT_04: string = '04';
  public static readonly MOVESTT_06: string = '06';
  public static readonly CHECKSTTFLG_1: string = '1';
  public static readonly DATEFORMAT: string = 'yyyy-MM-dd';
  public static readonly EMPTY_STRING: string = '';
  public static readonly STORECDCTRL: string = 'storeCd';
  public static readonly SLIPNOCTRL: string = 'slipNo';
  public static readonly PRODUCTCDCTRL: string = 'productCd';
  public static readonly REQSTATUSCTRL: string = 'reqStatus';
  public static readonly UPDDATETIMECTRL: string = 'updDatetime';
  public static readonly SLIPCOMMENTCTRL: string = 'slipComment';
  public static readonly APPROVERCOMMENTCTRL: string = 'approverComment';
  public static readonly TYPE_ELEMENT_CHECKBOX: string = 'checkbox';
  public static readonly TYPE_ELEMENT_INPUT_NUMBER: string = 'inputNumber';
  public static readonly TYPE_ELEMENT_INPUT_TEXT: string = 'inputText';
  public static readonly TYPE_ELEMENT_LINK: string = 'link';
  public static readonly TYPE_ELEMENT_DROPDOWN_LIST: string = 'dropdownList';
  public static readonly TYPE_ELEMENT_TEXT: string = 'text';
  public static readonly TYPE_ELEMENT_TEXT_NUMBER: string = 'textNumber';
  public static readonly TYPE_ELEMENT_TEXT_PRICE: string = 'textPrice';
  public static readonly MIN_VALUE_OF_DTQTY_FPMT1036: number = -99999;
  public static readonly MIN_VALUE_OF_DTQTY_FPMT1031: number = 1;
  public static readonly MAX_VALUE_OF_DTQTY: number = 99999;
  public static readonly MESSAGE_LOADING: string = '検索中・・・';
  public static readonly QTY_JP: string = '数量';
  public static readonly STOCK_QTY_JP: string = '在庫数';
  public static readonly PARAM_MESSAGE_PRODUCT_IS_EXIST: string = '廃棄・経費振替一覧';
  public static readonly PARAM_MESSAGE_CANNOT_REGISTER: string = '承認済又は取消済';
  public static readonly SPMT10311: string = 'spmt10311';
  public static readonly FPMT1031: string = 'FPMT1031';
  public static readonly FPMT1036: string = 'FPMT1036';
  public static readonly REQSTATUS_0: string = '0';
  public static readonly SLIPKBN_05: string = '05';
  public static readonly REDBLACKFLG_02: string = '02';
  public static readonly ZERO = 0;
  public static readonly LIMIT_RECORD = 1001;
}

@Component({
  selector: "app-spmt10311",
  templateUrl: "./spmt10311.component.html",
  styleUrls: ["./spmt10311.component.css"]
})
export class Spmt10311Component implements OnInit, OnDestroy {
  public itemsShow: ItemSelect[] = [];
  public items: ItemSelect[] = [];
  public insertList: ItemSelect[] = [];
  public allChecked: boolean = false;
  public itemRecordCount: number = 0;
  public columnIds: string[] = ["checkSttFlg","productCd","productNm","standardNm","slipKbn","dtQty","logicStockQty","reqStatusNm","sellPriceExc","totalPrice","dtlComment"];

  public columnDefsItems: Spmt10311TableColumnDef[] = [
    {columnId: "checkSttFlg", header: "削除", width: 45, align: "center",checkbox: true, type: Spmt10311Constant.TYPE_ELEMENT_CHECKBOX},
    {columnId: "productCd", header: "商品コード", width: 95, align: "left", editable: true, type: Spmt10311Constant.TYPE_ELEMENT_LINK},
    {columnId: "productNm", header: "商品名称", width: 200, align: "left", type: Spmt10311Constant.TYPE_ELEMENT_TEXT},
    {columnId: "standardNm", header: "規格", width: 95, align: "left", type: Spmt10311Constant.TYPE_ELEMENT_TEXT},
    {columnId: "slipKbn", header: "伝票区分", width: 95, align: "left", type: Spmt10311Constant.TYPE_ELEMENT_DROPDOWN_LIST},
    {columnId: "dtQty", header: "数量", width: 70, align: "right", type: Spmt10311Constant.TYPE_ELEMENT_INPUT_NUMBER},
    {columnId: "logicStockQty", header: "在庫数", width: 70, align: "right", type: Spmt10311Constant.TYPE_ELEMENT_TEXT_NUMBER},
    {columnId: "reqStatusNm", header: "申請状態", width: 75, align: "left", type: Spmt10311Constant.TYPE_ELEMENT_TEXT},
    {columnId: "sellPriceExc", header: "売価", width: 60, align: "right", type: Spmt10311Constant.TYPE_ELEMENT_TEXT_PRICE},
    {columnId: "totalPrice", header: "売価金額合計", width: 80, align: "right", type: Spmt10311Constant.TYPE_ELEMENT_TEXT_PRICE},
    {columnId: "dtlComment", header: "詳細コメント", width: 200, align: "left", type: Spmt10311Constant.TYPE_ELEMENT_INPUT_TEXT}
  ];

  public userInfo: UserInfoDto = null;
  public accessInfo: AccessInfo = {
    ...this.commonService.loginUser
  }
  public itemSelect: ItemSelect = null;
  public storeCdList: GetStoreListDto[] = [];
  public slipKbnList: DropdownListDto[] = [];
  public slipNoSearchResult: string = '';
  public oldSlipNo: string = '';
  public itemTableWidth: any;
  public isDisableSrchBtn: boolean = false;
  public isDisableConfirmBtn: boolean = true;
  public isDisableDeleteBtn: boolean = true;
  public isDisabledropdownstatus: boolean = false;
  public isShowSearchCondition: boolean = true;
  public tableWidth: any;
  public isFPMT1031: boolean = false;

  public searchCondition: FormGroup;
  public searchResult: FormGroup;
  private searchConditionSubscription: Subscription;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  @ViewChild("keypad", {static: true}) numberKeypadComponent: NumberKeypadComponent;
  constructor(
    public commonService: CommonService,
    private router: ActivatedRoute,
    public httpBasic: HttpBasicService,
    private message: MessageService,
    private fb: FormBuilder,
    private fbDetail: FormBuilder
  ) {}

  ngOnInit(): void {
    // this.commonService.pageTitle = this.commonService.pageMenuName;
    this.router.url.subscribe((urlSegments) => {
      let currentPath = urlSegments.map(segment => segment.path).join('/');
      this.isFPMT1031 = currentPath === Spmt10311Constant.SPMT10311;
      if(currentPath === Spmt10311Constant.SPMT10311){
        this.commonService.pageTitle = "廃棄・経費振替申請";
      } else {
        this.commonService.pageTitle = "在庫調整入力";
      }
    })

    this.searchCondition = this.fb.group({
      storeCd: [Spmt10311Constant.EMPTY_STRING,Validators.required],
      slipNo: [Spmt10311Constant.EMPTY_STRING],
      productCd: [Spmt10311Constant.EMPTY_STRING]
    },{
      validators: this.atLeastOneFilled(Spmt10311Constant.SLIPNOCTRL, Spmt10311Constant.PRODUCTCDCTRL)
    });

    this.searchResult = this.fbDetail.group({
      slipNo: [{value: Spmt10311Constant.EMPTY_STRING, disabled: true}],
      reqStatus: [{value: Spmt10311Constant.EMPTY_STRING, disabled: true}],
      updDatetime: [{value: Spmt10311Constant.EMPTY_STRING, disabled: true}],
      slipComment: [{value: Spmt10311Constant.EMPTY_STRING, disabled: false}],
      approverComment: [{value: Spmt10311Constant.EMPTY_STRING, disabled: true}]
    })

    this.set10KeyPadTarget(this.searchCondition.get(Spmt10311Constant.PRODUCTCDCTRL) as FormControl);
    this.calcTableWidth();
    this.initPrc();
    this.searchConditionValueChange();
  }

  async initPrc() {
    try{
      this.commonService.openSpinner(this.commonService.pageTitle, Spmt10311Constant.MESSAGE_LOADING);
      await this.getUserInfo();
      await this.getStoreList();
      await this.getInitPrc();
      this.commonService.closeSpinner();
    } catch(err) {
      this.commonService.closeSpinner();
    }
  }

  getUserInfo() {
    let request: ReqUserInfoSearch = {
      access: this.accessInfo
    }

    return new Promise<void>((resolve,reject) => {
      let subsc = this.httpBasic.getUserInfo(request).subscribe(
        result => {
          this.setResultGetUserInfo(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      )
    })
  }

  setResultGetUserInfo(response: RspUserInfo) {
    if (this.httpBasic.handleAppError(response)) return;
    if (response.rows.length > 0) {
      this.accessInfo = {
        ...this.commonService.loginUser,
        cmpnyCd: response.rows[0].mainCmpnyCd,
        lang: response.rows[0].mainLang,
        sysModeCd: response.rows[0].sysModeCd
      }
      this.userInfo = response.rows[0];
    }
  }

  getStoreList() {
    let request: ReqGetStoreList = {
      access: this.accessInfo,
      storeCd: Spmt10311Constant.EMPTY_STRING,
      userId: Spmt10311Constant.EMPTY_STRING,
      cmpyCd: this.accessInfo.cmpnyCd,
      sysModeCd: this.accessInfo.sysModeCd
    }

    return new Promise<void>((resolve,reject) => {
      let subsc = this.httpBasic.getStoreList(request).subscribe(
        result => {
          this.setResultGetStoreList(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      )
    })
  }

  setResultGetStoreList(response: RspGetStoreList) {
    if (this.httpBasic.handleAppError(response)) return;
    this.storeCdList = response.rows;
    this.searchCondition.get(Spmt10311Constant.STORECDCTRL).setValue(this.userInfo.mainStoreCd);
  }

  getInitPrc() {
    let request: ReqSpmt10311Init = {
      access: this.accessInfo
    }

    return new Promise<void>((resolve,reject) => {
      let subsc = this.httpBasic.initSpmt10311Init(request).subscribe(
        result => {
          this.setResultInit(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      )
    })
  }

  setResultInit(result: RspSpmt10311Init) {
    if (this.httpBasic.handleAppError(result)) return;
    if(this.isFPMT1031){
      this.slipKbnList = result.dropDownList.filter((i: DropdownListDto) => i.DATACD !== Spmt10311Constant.SLIPKBN_05);
    } else {
      this.slipKbnList = result.dropDownList.filter((i: DropdownListDto) => i.DATACD === Spmt10311Constant.SLIPKBN_05);
    }
  }

  atLeastOneFilled(ctrl1: string, ctrl2: string) {
    return (formGroup: FormControl) => {
      const control1 = formGroup.get(ctrl1);
      const control2 = formGroup.get(ctrl2);
      const isValid = !!control1?.value || !!control2?.value;

      return isValid ? null : { atLeastOneFilled: true };
    };
  }

  calcTableWidth() {
    this.tableWidth = { "width": "100%"};
  }

  private fnShowError( errorMessage: string, index: number) {
    const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
      data: {
        errorMessage: errorMessage,
        errorTitle: this.commonService.pageTitle
      }
    });

    let subcription = dialogRef.afterClosed().subscribe(() => {
      const el = document.getElementById(''+index);
      el.focus();
      subcription.unsubscribe();
    });
  }

  searchConditionValueChange() {
    this.searchConditionSubscription = this.searchCondition.valueChanges.subscribe(() => {
      this.updateButtonState();
    })
  }

  updateButtonState() {
    this.isDisableSrchBtn = !this.searchCondition.valid;
  }

  confirmBtn() {
    if(this.itemsShow.some((item: ItemSelect, index: number) => !this.checkDtQty(item, index, true))) {
      return;
    }

    const listInsert = this.getRowInsert();
    if(this.isFPMT1031){
      const listUpdate = this.getRowUpdate();
      if(listInsert.length !== 0 ||
        listUpdate.length !== 0 ||
        (this.itemSelect !== null && this.itemSelect.result.slipComment !== this.searchResult.get(Spmt10311Constant.SLIPCOMMENTCTRL).value)
      ) {
        let subcription = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200006]).subscribe((data: boolean) => {
          if(data) this.fnConfirmPrc(listInsert, listUpdate);
          subcription.unsubscribe();
        })
      }
      return;
    }

    let reqStatusNm = this.searchResult.get(Spmt10311Constant.REQSTATUSCTRL)?.value ?? '';
    if(this.itemsShow.length > 0 && !reqStatusNm){
      let subcription = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200006]).subscribe((data: boolean) => {
        if(data) this.fnConfirmPrc(listInsert, []);
        subcription.unsubscribe();
      })
    }
  }

  fnConfirmPrc(listInsert: Spmt10311Dto[], listUpdate: Spmt10311Dto[]) {
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10311Constant.MESSAGE_LOADING);
    let request: ReqSpmt10311Confirm = {
      access: this.accessInfo,
      funcId: this.isFPMT1031 ? Spmt10311Constant.FPMT1031 : Spmt10311Constant.FPMT1036,
      storeCd: this.searchCondition.get(Spmt10311Constant.STORECDCTRL).value,
      headerInfo: this.getHeaderInfo(),
      updateList: listUpdate,
      insertList: listInsert
    }

    let subsc = this.httpBasic.confirmSpmt10311(request).subscribe(
      result => {
        this.setResultConfirm(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  setResultConfirm(response: Rsp) {
    this.commonService.closeSpinner();
    if(response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if(response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });
      const subcription = dialogRef.afterClosed().subscribe(() => {
        this.clearSearchResult();
        this.insertList = [];
        subcription.unsubscribe();
      });
      return;
    }

    const dialogRef = this.commonService.dialog.open(NotificationDialogComponent, {
      data: {title: this.commonService.pageTitle, message: this.message.message[MI200002]}
    });
    const subcription = dialogRef.afterClosed().subscribe(() => {
      this.clearSearchResult();
      this.insertList = [];
      subcription.unsubscribe();
    });
  }

  getHeaderInfo(): Spmt10311HeaderInfo{
    return {
      slipNo: this.searchResult.get(Spmt10311Constant.SLIPNOCTRL).value,
      updDatetime: this.searchResult.get(Spmt10311Constant.UPDDATETIMECTRL).value,
      slipComment: this.searchResult.get(Spmt10311Constant.SLIPCOMMENTCTRL).value
    }
  }

  deleteBtn() {
    let subcription = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200002]).subscribe((data: boolean) => {
      if(data) this.deletePrc();
      subcription.unsubscribe();
    })
  }

  deletePrc() {
    this.filterSearchResult();
    const checkItems = this.itemsShow.filter((item: ItemSelect) => item.check.value && item.result.reqStatus !== Spmt10311Constant.EMPTY_STRING);
    if(checkItems.length === 0) {
      this.itemRecordCount = this.items.length ?? 0;
      this.deleteBtnChangeStatus();
      this.matPagenatorItem.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
      if(this.itemRecordCount <= 0) {
        this.isDisableConfirmBtn = true;
      }
      this.pageChanged();
      this.fnShowPopupSuccess(this.message.message[MI200001],false);
      return;
    }
    this.fnDeleteBtn();
  }

  filterSearchResult() {
    this.insertList = this.insertList.filter((i: ItemSelect) => !(i.check.value && i.result.reqStatus === Spmt10311Constant.EMPTY_STRING));
    const listDelete = this.itemsShow.filter((item: ItemSelect) => item.check.value && item.result.reqStatus === Spmt10311Constant.EMPTY_STRING);
    this.items = this.items.filter((item: ItemSelect) => !listDelete.some((i: ItemSelect) => i.result.productCd === item.result.productCd && item.result.reqStatus === Spmt10311Constant.EMPTY_STRING));
  }

  fnDeleteBtn(){
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10311Constant.MESSAGE_LOADING);
    let request: ReqSpmt10311Delete = {
      access: this.accessInfo,
      storeCd: this.searchCondition.get(Spmt10311Constant.STORECDCTRL).value,
      rows: this.getRowChecked()
    }

    let subsc = this.httpBasic.deleteSpmt10311(request).subscribe(
      result => {
        this.setResultDelete(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    );
  }

  setResultDelete(response: Rsp) {
    this.commonService.closeSpinner();
    if(response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if(response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });
      const subcription = dialogRef.afterClosed().subscribe(() => {
        this.clearSearchResult();
        this.searchPrc(false, true);
        subcription.unsubscribe();
      });
      return;
    }

    this.fnShowPopupSuccess(this.message.message[MI200001]);
  }

  getRowUpdate() {
    const list: Spmt10311Dto[] = [];

    this.itemsShow.forEach((item: ItemSelect) => {
      if(this.checkChange(item)) {
        list.push({
          ...item.result,
          dtQty: `${+item.dtQtyCtrl.value}`,
          slipKbn: item.slipKbnCtrl.value,
          slipDtlComment: item.dtlCommentCtrl.value
        })
      }
    })
    return list;
  }

  checkChange(item: ItemSelect): boolean{
    if(item.result.reqStatus === Spmt10311Constant.EMPTY_STRING) {
      return false
    }
    if(item.dtQtyCtrl.value !== item.result.dtQty ||
      item.slipKbnCtrl.value !== item.result.slipKbn ||
      item.dtlCommentCtrl.value !== item.result.slipDtlComment
    ) {
      return true;
    }
    return false;
  }

  getRowInsert() {
    const list: Spmt10311Dto[] = [];

    this.itemsShow.forEach((item: ItemSelect) => {
      if(item.result.reqStatus === Spmt10311Constant.EMPTY_STRING) {
        list.push({
          ...item.result,
          dtQty: `${+item.dtQtyCtrl.value}`,
          slipKbn: item.slipKbnCtrl.value,
          slipDtlComment: item.dtlCommentCtrl.value
        })
      }
    })
    return list;
  }

  getRowChecked() {
    const list: Spmt10311Dto[] = [];

    this.itemsShow.forEach((item: ItemSelect) => {
      if(item.check.value) {
        list.push({
          ...item.result,
          dtQty: `${+item.dtQtyCtrl.value}`,
          slipKbn: item.slipKbnCtrl.value,
          slipDtlComment: item.dtlCommentCtrl.value
        })
      }
    })
    return list;
  }

  fnShowPopupSuccess(message: string, isReload: boolean = true) {
    const dialogRef = this.commonService.dialog.open(NotificationDialogComponent, {
      data: {title: this.commonService.pageTitle, message: message}
    });
    const subcription = dialogRef.afterClosed().subscribe(() => {
      if(isReload){
        this.clearSearchResult();
        this.searchPrc(false, true);
      }
      subcription.unsubscribe();
    });
  }

  pageChangedItem() {
    if(this.itemsShow?.length > 0) this.pageChanged();
  }

  clearSearchResult() {
    this.resetSearchResult();
    this.items = [];
    this.itemsShow = [];
    this.itemSelect = null;
    this.allChecked = false;
    this.itemRecordCount = 0;
    this.isDisableConfirmBtn = true;
    this.isDisableDeleteBtn = true;
  }

  resetSearchResult() {
    this.searchResult.reset({
      slipComment: Spmt10311Constant.EMPTY_STRING,
      slipNo: Spmt10311Constant.EMPTY_STRING,
      reqStatus: Spmt10311Constant.EMPTY_STRING,
      updDatetime: Spmt10311Constant.EMPTY_STRING,
      approverComment: Spmt10311Constant.EMPTY_STRING
    });
    this.searchResult.get(Spmt10311Constant.SLIPCOMMENTCTRL).enable();
   
  }

  clearBtn() {
    if(this.items.length > 0){
      let subcription = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200010]).subscribe((data: boolean) => {
        if(data) this.clearPrc();
        subcription.unsubscribe();
      })
      return;
    }
    this.clearPrc();
  }

  clearPrc(){
    this.searchCondition.reset({
      storeCd: this.userInfo.mainStoreCd,
      slipNo: Spmt10311Constant.EMPTY_STRING,
      productCd: Spmt10311Constant.EMPTY_STRING
    })
    this.insertList = [];
    this.set10KeyPadTarget(this.searchCondition.get(Spmt10311Constant.PRODUCTCDCTRL) as FormControl);
    if(this.isKeyboardLocked()) {
      this.numberKeypadComponent.onClickKeyboardLock();
    }
    this.matPagenatorItem._changePageSize(this.commonService.paginatorOption.pageSizeOptions[0]);
    this.clearSearchResult();
  }

  pageChanged() {
    this.allChecked = false;
    this.isDisableDeleteBtn = true;
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.items[i].check.setValue(false);
      this.items[i].dtQtyCtrl.setValue(this.items[i].result.dtQty)
      this.items[i].slipKbnCtrl.setValue(this.items[i].result.slipKbn)
      this.items[i].dtlCommentCtrl.setValue(this.items[i].result.slipDtlComment)
      this.itemsShow.push(this.items[i]);
      count++;
    }
    let id = "item-table-box";
    // document.getElementById(id)?.scrollTo(0, 0);
  }

  allCheck(value: boolean) {
    this.itemsShow.forEach((rec) => {
      if(!rec.check.disabled) {
        rec.check.setValue(value);
      }
    });
    this.allChecked = value;
    this.deleteBtnChangeStatus();
  }

  updateAllChecked() {
    this.deleteBtnChangeStatus();
    this.isCheckedAll();
  }

  isCheckedAll() {
    this.allChecked = !this.itemsShow.some(i => !i.check.disabled && !i.check.value)
  }

  searchClickBtn() {
    if(!this.isFPMT1031 && 
      this.searchCondition.get(Spmt10311Constant.SLIPNOCTRL).value !== Spmt10311Constant.EMPTY_STRING &&
      this.searchCondition.get(Spmt10311Constant.PRODUCTCDCTRL).value !== Spmt10311Constant.EMPTY_STRING
    ){
      this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: this.message.message[ME200016].replace('%1', Spmt10311Constant.PARAM_MESSAGE_CANNOT_REGISTER)}
      });
      return;
    }

    if(this.checkProductIsExist()){
      this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: this.message.message[ME200007].replace('%1', Spmt10311Constant.PARAM_MESSAGE_PRODUCT_IS_EXIST)}
      });
      return;
    }

    if(this.searchCondition.get(Spmt10311Constant.PRODUCTCDCTRL).value !== Spmt10311Constant.EMPTY_STRING) {
      this.checkBeforeSearch();
      return;
    }

    this.clearSearchResult();
    this.searchBtn();
  }

  checkProductIsExist(){
    if(this.oldSlipNo !== this.searchCondition.get(Spmt10311Constant.SLIPNOCTRL).value){
      this.insertList = [];
      return false;
    }
    let productCd = this.searchCondition.get(Spmt10311Constant.PRODUCTCDCTRL).value;
    return this.insertList.some((i: ItemSelect) => i.result.productCd === productCd);
  }

  checkBeforeSearch() {
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10311Constant.MESSAGE_LOADING);
    const request: ReqSpmt10311CheckBeforeSearch = {
      storeCd: this.searchCondition.get(Spmt10311Constant.STORECDCTRL).value,
      slipNo: this.searchCondition.get(Spmt10311Constant.SLIPNOCTRL).value,
      productCd: this.searchCondition.get(Spmt10311Constant.PRODUCTCDCTRL).value,
      access: this.accessInfo
    }
    const subsc = this.httpBasic.checkBeforeSearchSpmt10311(request).subscribe(
      result => {
        this.checkResultCheckBeforeSearch(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResultCheckBeforeSearch(response: Rsp) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;
    this.getDetailPrc();
  }

  getDetailPrc() {
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10311Constant.MESSAGE_LOADING);
    const request: ReqSpmt10311GetDetail = {
      storeCd: this.searchCondition.get(Spmt10311Constant.STORECDCTRL).value,
      slipNo: this.searchCondition.get(Spmt10311Constant.SLIPNOCTRL).value,
      productCd: this.searchCondition.get(Spmt10311Constant.PRODUCTCDCTRL).value,
      access: this.accessInfo
    }
    const subsc = this.httpBasic.getDetailSpmt10311(request).subscribe(
      result => {
        this.checkResultGetDetail(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResultGetDetail(response: RspSpmt10311GetDetail) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) return;
    const dialogRef = this.commonService.dialog.open(Spmt10311DetailDialogComponent,{
      autoFocus: false,
      data: {
        slipKbnList: this.slipKbnList,
        isUpdate: false,
        row: this.generateRowData(response.row),
        isFPMT1031: this.isFPMT1031
      }
    })
    const subscription = dialogRef.afterClosed().subscribe((data: DTRDetailDto) => {
      if(data) {
        this.setDataOfSearchResult(data);
      }
      subscription.unsubscribe();
    })
  }

  setDataOfSearchResult(data: DTRDetailDto) {
    const slipNo = this.searchCondition.get(Spmt10311Constant.SLIPNOCTRL).value;
    if(this.oldSlipNo !== slipNo) {
      this.oldSlipNo = slipNo;
      this.insertList = [];
    }

    const row: ItemSelect = {
      ...data,
      result: {
        ...data.result,
        dtQty: data.dtQtyCtrl.value,
        slipDtlComment: data.dtlCommentCtrl.value,
        slipKbn: data.slipKbnCtrl.value
      },
      check: new FormControl(false)
    }
    this.insertList.push(row);

    this.set10KeyPadTarget(row.dtQtyCtrl as FormControl);
    if(slipNo !== Spmt10311Constant.EMPTY_STRING) {
      this.searchBtn(true);
      return;
    }

    this.items = [...this.insertList];
    this.matPagenatorItem.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
    this.itemRecordCount = this.items.length ?? 0;
    this.resetSearchResult();
    if(this.itemRecordCount > 0) {
      this.isDisableConfirmBtn = false;
    }
    this.pageChanged();
  }

  generateRowData(data: Spmt10311GetDetailDto) {
    let row: Spmt10311Dto = {
      ...data,
      slipDtlNo: Spmt10311Constant.EMPTY_STRING,
      slipNo: Spmt10311Constant.EMPTY_STRING,
      redBlackFlg: Spmt10311Constant.EMPTY_STRING,
      reqStatus: Spmt10311Constant.EMPTY_STRING,
      reqStatusNm: Spmt10311Constant.EMPTY_STRING,
      slipComment: Spmt10311Constant.EMPTY_STRING,
      approverComment: Spmt10311Constant.EMPTY_STRING,
      slipKbn: this.slipKbnList[0].DATACD,
      dtQty: Spmt10311Constant.EMPTY_STRING,
      totalPrice: Spmt10311Constant.ZERO,
      totalOrderPrice: Spmt10311Constant.ZERO,
      slipDtlComment: Spmt10311Constant.EMPTY_STRING,
      updDatetime: Spmt10311Constant.EMPTY_STRING
    }
    return row;
  }

  searchBtn(insertMode: boolean = false) {
    if(!insertMode) {
      this.insertList = [];
    }
    this.searchPrc(insertMode);
  }

  getPageDto():ReqPageDto {
    return {
      pageNum: Spmt10311Constant.ZERO,
      dispNum: Spmt10311Constant.LIMIT_RECORD
    }
  }

  getDate(ctrlNm: string) {
    if(this.searchCondition.get(ctrlNm).value === null) return Spmt10311Constant.EMPTY_STRING;
    return formatDate(this.searchCondition.get(ctrlNm).value, Spmt10311Constant.DATEFORMAT, 'en_US');
  }

  searchPrc(insertMode: boolean, isDeleteMode: boolean = false) {
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10311Constant.MESSAGE_LOADING);
    const request: ReqSpmt10311Search = {
      funcId: this.isFPMT1031 ? Spmt10311Constant.FPMT1031 : Spmt10311Constant.FPMT1036,
      storeCd: this.searchCondition.get(Spmt10311Constant.STORECDCTRL).value,
      slipNo: this.searchCondition.get(Spmt10311Constant.SLIPNOCTRL).value,
      productCd: this.searchCondition.get(Spmt10311Constant.PRODUCTCDCTRL).value,
      access: this.accessInfo,
      pageDto: this.getPageDto()
    }
    const subsc = this.httpBasic.searchSpmt10311(request).subscribe(
      result => {
        subsc.unsubscribe();
        this.checkResult(result, insertMode, isDeleteMode);
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpmt10311Search, insertMode: boolean, isDeleteMode: boolean) {
    this.commonService.closeSpinner();
    if(response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if(response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });
      const subcription = dialogRef.afterClosed().subscribe(() => {
        if(insertMode) this.insertList.pop();
        subcription.unsubscribe();
      });
      return;
    }
    if(response.results.length === 0) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001]);

      if(isDeleteMode && this.insertList.length > 0){
        this.items = [...this.insertList];
        this.itemRecordCount = this.items.length ?? 0;
        this.isDisableConfirmBtn = false;
        this.matPagenatorItem.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
        this.pageChanged();
      }
      return;
    }

    this.items = [...this.insertList];
    for(let i = 0; i < Spmt10311Constant.MAXITEMS; i ++){
      if(i >= response.results.length) break;
      let isDisable: boolean = this.isDisableStatusWhenSearch(response.results[i].reqStatus);
      let row: ItemSelect = {
        check: new FormControl({value: false, disabled: isDisable}),
        slipKbnCtrl: new FormControl({value: response.results[i].slipKbn, disabled:  isDisable }),
        dtQtyCtrl: new FormControl({value: response.results[i].dtQty, disabled:  isDisable}),
        dtlCommentCtrl: new FormControl({value: response.results[i].slipDtlComment, disabled: isDisable}),
        result: response.results[i]
      }
      this.items.push(row)
    }

    this.itemRecordCount = this.items.length ?? 0;
    if(this.itemRecordCount > 0) {
      this.isDisableConfirmBtn = false;
    }
    if(response.results.length > 0) {
      let count = this.insertList.length;
      this.itemSelect = this.items[count];
      this.searchResult.get(Spmt10311Constant.SLIPCOMMENTCTRL).setValue(this.itemSelect.result.slipComment);
      this.changeStatusOfSlipComment();
      this.searchResult.get(Spmt10311Constant.SLIPNOCTRL).setValue(this.itemSelect.result.slipNo);
      this.searchResult.get(Spmt10311Constant.REQSTATUSCTRL).setValue(this.itemSelect.result.reqStatusNm);
      this.searchResult.get(Spmt10311Constant.UPDDATETIMECTRL).setValue(this.itemSelect.result.updDatetime);
      this.searchResult.get(Spmt10311Constant.APPROVERCOMMENTCTRL).setValue(this.itemSelect.result.approverComment);
    }
    if(response.results.length > Spmt10311Constant.MAXITEMS) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle,this.message.message[MI200003].replace(/%1/g,Spmt10311Constant.MAXITEMS));
    }
    this.matPagenatorItem.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
    this.pageChanged();
  }

  isDisableStatusWhenSearch(reqStatus: string){
    if(this.isFPMT1031){
      return reqStatus !== Spmt10311Constant.REQSTATUS_0;
    }
    return true;
  }

  changeStatusOfSlipComment(){
    const slipComment = this.searchResult.get(Spmt10311Constant.SLIPCOMMENTCTRL);
    if(this.isFPMT1031 && this.itemSelect.result.reqStatus === Spmt10311Constant.REQSTATUS_0){
      slipComment.enable();
      return;
    }
    slipComment.disable();
  }

  onClickLink(item: ItemSelect) {
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10311Constant.MESSAGE_LOADING);
    const request: ReqSpmt10311Exclusion = {
      slipNo: item.result.slipNo,
      updDatetime: item.result.updDatetime,
      access: this.accessInfo
    }
    const subsc = this.httpBasic.exclusionSpmt10311(request).subscribe(
      result => {
        subsc.unsubscribe();
        this.checkResultExclusion(result, item);
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResultExclusion(response: Rsp, item: ItemSelect){
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });
      const subscription = dialogRef.afterClosed().subscribe(() => {
        this.clearSearchResult();
        this.searchBtn();
        subscription.unsubscribe();
      })
      return;
    }
    this.openModalDetail(item)
  }

  openModalDetail(item: ItemSelect){
    const dialogRef = this.commonService.dialog.open(Spmt10311DetailDialogComponent, {
      autoFocus: false,
      data: {
        slipKbnList: this.slipKbnList,
        isUpdate: true,
        isFPMT1031: this.isFPMT1031,
        row: this.getDataDetail(item),
        isInsert: item.result.reqStatus === Spmt10311Constant.EMPTY_STRING
      }
    });

    let subcription = dialogRef.afterClosed().subscribe((data: DTRDetailDto) => {
      if(data) {
        this.itemsShow = this.itemsShow.map((item: ItemSelect) => {
          if(item.result.productCd === data.result.productCd) {
            item.dtQtyCtrl.setValue(data.dtQtyCtrl.value);
            item.slipKbnCtrl.setValue(data.slipKbnCtrl.value);
            item.dtlCommentCtrl.setValue(data.dtlCommentCtrl.value);
            return {
              ...item,
              result: data.result
            }
          }
          return item;
        });
      }
      subcription.unsubscribe();
    });
  }

  getDataDetail(item: ItemSelect): Spmt10311Dto{
    return {
      ...item.result,
      slipKbn: item.slipKbnCtrl.value,
      dtQty: item.dtQtyCtrl.value,
      slipDtlComment: item.dtlCommentCtrl.value
    }
  }

  isDisableCheckAll(){
    if(this.itemsShow.length <= 0 || this.itemsShow.every((i: ItemSelect) => i.check.disabled)){
      return true;
    }
    return false;
  }

  checkDtQty(item: ItemSelect, index: number, isRegis: boolean = false): boolean{
    if(item.dtQtyCtrl.value === Spmt10311Constant.EMPTY_STRING || item.dtQtyCtrl.value === '-') {
      if(isRegis){
        this.fnShowError(this.message.message[ME200003].replace('%1', Spmt10311Constant.QTY_JP), index);
      } else {
        this.fnShowError(this.message.message[ME100010], index);
      }
      return false;
    }
    if(this.checkMinMaxLength(item, index)) {
      return false
    }
    if(this.isFPMT1031){
      if(+item.dtQtyCtrl.value > +item.result.logicStockQty) {
        if(isRegis){
          this.fnShowError(this.message.message[ME200001].replace('%1', Spmt10311Constant.STOCK_QTY_JP).replace('%2', Spmt10311Constant.QTY_JP), index);
        } else {
          this.fnShowError(this.message.message[ME200004].replace('%1', Spmt10311Constant.QTY_JP).replace('%2', Spmt10311Constant.STOCK_QTY_JP), index);
        }
        return false
      }
    } else {
      let dtQty: number = +item.dtQtyCtrl.value;
      if(dtQty < 0 && Math.abs(dtQty) > +item.result.logicStockQty) {
        if(isRegis){
          this.fnShowError(this.message.message[ME200001].replace('%1', Spmt10311Constant.STOCK_QTY_JP).replace('%2', Spmt10311Constant.QTY_JP), index);
        } else {
          this.fnShowError(this.message.message[ME200004].replace('%1', Spmt10311Constant.QTY_JP).replace('%2', Spmt10311Constant.STOCK_QTY_JP), index);
        }
        return false
      }
    }

    item.result.totalPrice = item.result.sellPriceExc * +item.dtQtyCtrl.value;
    item.result.totalOrderPrice = item.result.orderPriceExc * +item.dtQtyCtrl.value;
    return true;
  }

  checkMinMaxLength(item: ItemSelect, index: number): boolean{
    const minValue = this.isFPMT1031 ? Spmt10311Constant.MIN_VALUE_OF_DTQTY_FPMT1031 : Spmt10311Constant.MIN_VALUE_OF_DTQTY_FPMT1036;
    if(+item.dtQtyCtrl.value < +minValue || +item.dtQtyCtrl.value > +Spmt10311Constant.MAX_VALUE_OF_DTQTY) {
      this.fnShowError(this.message.message[ME200005].replace('%1', Spmt10311Constant.QTY_JP).replace('%2', ''+minValue).replace('%3', Spmt10311Constant.MAX_VALUE_OF_DTQTY), index);
      return true;
    }
    return false;
  }

  btnListSlipNoClick() {
    const dialogRef = this.commonService.dialog.open(Spmt10311SlipNoListDialogComponent,{
      autoFocus: false,
      data: {
        storeCd: this.searchCondition.get(Spmt10311Constant.STORECDCTRL).value,
        isFPMT1031: this.isFPMT1031,
        accessInfo: this.accessInfo
      }
    });
    let subcription = dialogRef.afterClosed().subscribe((data) => {
      if(data) {
        this.searchCondition.get(Spmt10311Constant.SLIPNOCTRL).setValue(data.slipNo);
        this.searchClickBtn();
      }
      subcription.unsubscribe();
    });
  }

  deleteBtnChangeStatus() {
    if(this.itemsShow.some(i => i.check.value)) {
      this.isDisableDeleteBtn = false;
      return;
    }
    this.isDisableDeleteBtn = true
  }

  isKeyboardLocked() {
    return this.numberKeypadComponent.isKeyboardLocked() ?? false;
  }

  checkIs10KeyPadTarget(ctrl: string) {
    return this.is10KeyPadTarget(this.searchCondition.get(ctrl) as FormControl);
  }

  is10KeyPadTarget(ctrl: FormControl): boolean{
    return ctrl === this.numberKeypadComponent.inputField;
  }

  setKeyPad(control: string, maxCol: number = 10, stringMode?: boolean) {
    this.set10KeyPadTarget(this.searchCondition.get(control) as FormControl, maxCol, stringMode);
  }

  set10KeyPadTarget(control: FormControl, maxCol: number = 10, stringMode?: boolean) {
    if(!this.commonService.config.use10KeyPad) {
      return;
    }
    this.numberKeypadComponent.setTargetForm(control);
    this.numberKeypadComponent.setMaxCol(maxCol);
    if(stringMode) {
      this.numberKeypadComponent.setStringMode();
    }
  }

  openErrDialog(msg: string) {
    this.commonService.dialog.open(ErrorNotificationDialogComponent, {
      data: {
        errorMessage: msg,
        errorTitle: this.commonService.pageTitle
      }
    });
  }

  onScan(barcode: string) {
    if(barcode){
      this.fnExecAfterScan(barcode);
    }
  }

  private fnExecAfterScan(barcode: string){
    let productCd = barcode;
    if(this.commonService.config.loginBarcode) {
      productCd = barcode.substring(this.commonService.config.loginBarcode.start, this.commonService.config.loginBarcode.end);
    }
    this.searchCondition.get(Spmt10311Constant.PRODUCTCDCTRL).setValue(productCd);
    if(productCd){
      this.searchClickBtn();
    }
  }

  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data != undefined && data != null) {
        this.fnExecAfterScan(data);
      }
    });
  }

  colStyle(item: Spmt10311Dto) {
    return item.redBlackFlg === Spmt10311Constant.REDBLACKFLG_02;
  }

  ngOnDestroy() {
    if(this.searchConditionSubscription) this.searchConditionSubscription.unsubscribe();
  }
}
