import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ScheduleDto, StoreDto } from 'src/app/response/rsp-inventory-schedule-list';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';
import { InvScheduleSearchDto } from 'src/app/request/req-inventory-schedule-list';
import { Spiv00051Dto, Spiv00051List, Spiv00051SearchDto, ReqSpiv00051Search, RspSpiv00051Search } from 'src/app/request/req-spiv00051';
import { InvMonthSearchDto } from 'src/app/request/req-inventory-month-list';
import { InvMonthListDto } from 'src/app/response/rsp-inventory-month-list';
import { ValidateService } from 'src/app/service/validate.service';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { SectionHeaderUpComponent } from 'src/app/partsCommon/section-header-up/section-header-up.component';
import { Spiv00081SearchDto } from 'src/app/request/req-spiv00081-search';
import { MessageService } from 'src/app/service/message.service';
import * as MessageID from 'src/app/common/const-message-id';
import { Spiv00091SearchDto } from 'src/app/request/req-spiv00091-search';
import { Spiv00101Component } from '../spiv00101/spiv00101.component';
import { Spiv00111Component } from '../spiv00111/spiv00111.component';
import { NotificationDialogComponent } from 'src/app/dialog/notification-dialog/notification-dialog.component';
import { CsvExportComponent } from '../common/csv-export/csv-export.component';
import { UserInfoDto } from 'src/app/response/rsp-user-info';

@Component({
  selector: 'app-spiv00051',
  templateUrl: './spiv00051.component.html',
  styleUrls: ['./spiv00051.component.css']
})
export class Spiv00051Component implements OnInit, OnDestroy {
  // Defined variables
  public form: FormGroup;
  public isDiffQtyCheck: FormControl = new FormControl();
  public isNoDiffQtyCheck: FormControl = new FormControl();

  userInfo: UserInfoDto[] = [];
  public stores: StoreDto[];
  monthLists: InvMonthListDto[] = [];
  scheduleLists: ScheduleDto[] = [];
  gmainClassList: any;
  gmidClassList: any;
  gsmallClassList: any;
  gsubClassList: any;
  isOwnPage: boolean = true;
  isCsvImportBtnDisabled: boolean = false;
  isCsvExportBtnDisabled: boolean = false;
  inventoryYearMonth: string = "";
  cmpnyCd: string;
  storeCd: string;
  sysModeCd: string;
  lang: string;
  searchExpandBtn: boolean;
  categoryExpandBtn: boolean;
  parentPage: string;
  loginStoreCd: any;
  searchData: Spiv00051SearchDto;
  fpiv0008SearchData: Spiv00081SearchDto;
  fpiv0009SearchData: Spiv00091SearchDto;
  invProductDialog: MatDialogRef<Spiv00101Component>;
  detailDialog: MatDialogRef<Spiv00111Component>;
  csvExportDialog: MatDialogRef<CsvExportComponent>;
  // Messages
  productCdMessage: string = "";
  maxLengthMsg = this.messageEntity.message[MessageID.ME100015].replace('%1', '-999999').replace('%2', '999999');
  startEndMsg = this.messageEntity.message[MessageID.ME100014];
  // Table
  public recordCount: number = 0;
  public inventoryEntryList: Spiv00051List[];
  public inventoryEntryListDisplay = new MatTableDataSource<Spiv00051Dto>([]);
  public columnIds: string[] = ["productCd", "productNm", "standardNm", "inventoryQty", "stockQty", "posPrice", "posPriceAmt", "salePrice", "salePriceAmt", "gmainClass", "gmidClass", "gsmallClass", "gsubClass"];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'productCd', header: "商品コード", width: 100 },
    { columnId: 'productNm', header: "商品名", width: 200 },
    { columnId: 'standardNm', header: "規格", width: 100 },
    { columnId: 'inventoryQty', header: "棚卸数", width: 80, align: 'right', editable: true },
    { columnId: 'stockQty', header: "在庫数", width: 80, align: 'right' },
    { columnId: 'posPrice', header: "POS原価(抜)", width: 100, align: 'right' },
    { columnId: 'posPriceAmt', header: "POS原価金額(抜)", width: 120, align: 'right' },
    { columnId: 'salePrice', header: "売価(抜)", width: 100, align: 'right' },
    { columnId: 'salePriceAmt', header: "売価金額(抜)", width: 120, align: 'right' },
    { columnId: 'gmainClass', header: "大分類", width: 150 },
    { columnId: 'gmidClass', header: "中分類", width: 200 },
    { columnId: 'gsmallClass', header: "小分類", width: 250 },
    { columnId: 'gsubClass', header: "細分類", width: 300 }
  ];

  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(SectionHeaderComponent, { static: true }) sectionHeader: SectionHeaderComponent;
  @ViewChild(SectionHeaderUpComponent, { static: true }) sectionHeaderUp: SectionHeaderUpComponent;

  constructor (
    private fb: FormBuilder,
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    public messageEntity: MessageService,
    private dialog: MatDialog,
    private router: Router,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.initFormGroup();
    this.setInitPage();
  }
  ngOnDestroy(): void {
  }

  initFormGroup() {
    this.form = this.fb.group({
      storeCd: ['', [Validators.required]],
      invPeriod: [""],
      invMonth: [""],
      inventoryScheduleId: ['', [Validators.required]],
      inventoryStatus: [{value:"", disabled: true}],
      inventoryDate: [{value:"", disabled: true}],
      productCd: ['', [ValidateService.maxLength(13)]],
      productNm: [""],
      gmainClassCd: [""],
      gmidClassCd: [""],
      gsmallClassCd: [""],
      gsubClassCd: [""],
      inventoryQtyFrom: ['', [ValidateService.minValue(-999999), ValidateService.maxValue(999999)]],
      inventoryQtyTo: ['', [ValidateService.minValue(-999999), ValidateService.maxValue(999999)]],
      stockQtyFrom: ['', [ValidateService.minValue(-999999), ValidateService.maxValue(999999)]],
      stockQtyTo: ['', [ValidateService.minValue(-999999), ValidateService.maxValue(999999)]],
    }, { 
      validators: [
        ValidateService.startLessThanEnd('inventoryQtyFrom', 'inventoryQtyTo', 'inventoryQtyRange'), 
        ValidateService.startLessThanEnd('stockQtyFrom', 'stockQtyTo', 'stockQtyRange')
      ]
    });
    this.isDiffQtyCheck.setValue(true);
    this.isNoDiffQtyCheck.setValue(true);
  }

  async setInitPage(): Promise<void> {
    await this.getUserInfo();

    try {
      // Call from another screen or not
      if (this.commonService.fpiv0005ParentPage !== undefined) {
        this.isOwnPage = false;
        this.parentPage = this.commonService.fpiv0005ParentPage;
        this.commonService.fpiv0005ParentPage = undefined;
        this.searchData = this.commonService.fpiv0005SearchData;
        this.commonService.fpiv0005SearchData = undefined;

        // Check search data is exist or not
        if (this.searchData != undefined) {
          // Load init data
          await this.getStoreLists();
          await this.getInvMonthLists(this.searchData.storeCd);
          await this.initCategoryList();

          this.form.get('storeCd').setValue(this.searchData.storeCd ?? "");
          this.form.get('invMonth').setValue(this.searchData.invYearMonth ?? "");
          this.form.get('inventoryScheduleId').setValue(this.searchData.invScheduleId ?? "");
          this.form.get('productCd').setValue(this.searchData.productCd ?? "");
          this.form.get('productNm').setValue(this.searchData.productNm ?? "");
          this.form.get('gmainClassCd').setValue(this.searchData.gmainClassCd ?? "");
          this.form.get('gmidClassCd').setValue(this.searchData.gmidClassCd ?? "");
          this.form.get('gsmallClassCd').setValue(this.searchData.gsmallClassCd ?? "");
          this.form.get('gsubClassCd').setValue(this.searchData.gsubClassCd ?? "");
          this.form.get('inventoryQtyFrom').setValue(this.searchData.inventoryQtyFrom ?? "");
          this.form.get('inventoryQtyTo').setValue(this.searchData.inventoryQtyTo ?? "");
          this.form.get('stockQtyFrom').setValue(this.searchData.stockQtyFrom ?? "");
          this.form.get('stockQtyTo').setValue(this.searchData.stockQtyTo ?? "");
        }
        // Check parent page
        if (this.parentPage === 'fpiv0008' || this.parentPage === 'fpiv0009') {
          if (this.parentPage === 'fpiv0008') this.fpiv0008SearchData = this.commonService.fpiv0008SearchData;
          if (this.parentPage === 'fpiv0009') this.fpiv0009SearchData = this.commonService.fpiv0009SearchData;
          this.commonService.fpiv0008SearchData = undefined;
          this.commonService.fpiv0009SearchData = undefined;
          // Disable store code and schecule id
          this.form.get('storeCd').disable();
          this.form.get('inventoryScheduleId').disable();
        }
      } else {
        // Load init data
        await this.getStoreLists();
        await this.getInvMonthLists(this.storeCd);
        await this.initCategoryList();
      }

      await this.getScheduleLists();
      this.doSearch(false);
      this.commonService.closeSpinner();
    } catch (error) {
      this.commonService.closeSpinner();
      this.httpBasic.handleError(error);
    }
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }
  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }
  styleForRow(colDef: TableColumnDef) {
    return {
      "width": "" + colDef.width + "px",
      "min-width": "" + colDef.width + "px",
      "max-width": "" + (colDef.maxWidth != undefined ? colDef.maxWidth : colDef.width) + "px",
      "text-align": colDef.align ? colDef.align : "left"
    }
  }
  getTblMaxHeight(): string {
    this.searchExpandBtn = this.sectionHeader.isSectionBodyOpen;
    if (this.searchExpandBtn && this.categoryExpandBtn) {
      return '310px';
    } else if (this.searchExpandBtn && !this.categoryExpandBtn) {
      return '500px';
    } else if (!this.searchExpandBtn) {
      return '581px';
    }
  }
  setBtnName() {
    this.categoryExpandBtn = this.sectionHeaderUp.isSectionBodyOpen;
    return this.categoryExpandBtn ? "詳細条件を閉じる" : "詳細条件を表示";
  }

  getUserInfo(): Promise<void> {
    return new Promise((resolve, reject) => {
      var request = {
        access: { ...this.commonService.loginUser }
      };
      let subs = this.httpBasic.generalRequest("GetUserInfo", request).subscribe(
        response => {
          subs.unsubscribe();
          this.receiveUserInfo(response);
          resolve();
        },
        error => {
          subs.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveUserInfo(response: any) {
    if (this.httpBasic.handleAppError(response)) return;
    if (response.rows.length > 0) {
      this.cmpnyCd = response.rows[0].mainCmpnyCd;
      this.storeCd = response.rows[0].mainStoreCd;
      this.sysModeCd = response.rows[0].sysModeCd;
      this.lang = response.rows[0].mainLang;
      this.commonService.loginUser.cmpnyCd = this.cmpnyCd;
    } else {
      this.cmpnyCd = "";
      this.storeCd = "";
      this.sysModeCd = "";
      this.lang = "";
    }
  }

  getStoreLists(): Promise<void> {
    return new Promise((resolve, reject) => {
      var request = {
        access: { ...this.commonService.loginUser },
        cmpyCd: this.cmpnyCd,
        storeCd: this.storeCd,
        sysModeCd: this.sysModeCd
      };
      let subs = this.httpBasic.generalRequest("GetStoreList", request).subscribe(
        response => {
          subs.unsubscribe();
          this.receiveStoreList(response);
          resolve();
        },
        error => {
          subs.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveStoreList(response: any) {
    if (this.httpBasic.handleAppError(response)) return;
    this.stores = [];
    if (response.rows.length > 0) {
      this.form.get("storeCd").setValue(this.storeCd);
      this.stores = response.rows;
    } else {
      this.monthLists = [];
    }
    
    if (this.stores.length === 1) {
      this.form.get("storeCd").disable();
    } else {
      this.form.get("storeCd").enable();
    }
  }

  getInvMonthLists(storeCd: string): Promise<void> {
    return new Promise((resolve, reject) => {
      var request: InvMonthSearchDto = {
        storeCd: storeCd,
        cmpyCd: this.cmpnyCd,
        order: "1", // for descending order
        access: { ...this.commonService.loginUser },
      };
      let subsc = this.httpBasic.generalRequest("GetInvMonthList", request).subscribe(
        response => {
          subsc.unsubscribe();
          this.receiveMonthList(response);
          resolve();
        },
        error => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveMonthList(response: any) {
    if (this.httpBasic.handleAppError(response)) return;
    this.monthLists = [];
    if (response.rows.length > 0) {
      // this.form.get("invMonth").setValue(response.rows[0].invScheduleId);
      this.monthLists = response.rows;
    }
    if (response.selected.length > 0) {
      this.form.get("inventoryScheduleId").setValue(response.selected[0].invScheduleId);
      this.form.get("invMonth").setValue(response.selected[0].invMonth);
      this.form.get("invPeriod").setValue(response.selected[0].invPeriod);
    } else {
      this.form.get("inventoryScheduleId").setValue("");
      this.form.get("invMonth").setValue("");
      this.form.get("invPeriod").setValue("");
    }
  }

  getScheduleLists(): Promise<void> {
    return new Promise((resolve, reject) => {
      var request: InvScheduleSearchDto = {
        invScheduleId: this.form.get("inventoryScheduleId").value,
        storeCd: this.form.get("storeCd").value,
        cmpyCd: this.cmpnyCd,
        invMonth: this.form.get("invMonth").value,
        access: { ...this.commonService.loginUser },
      };
      let subsc = this.httpBasic.generalRequest("GetInvScheduleList", request).subscribe(
        response => {
          subsc.unsubscribe();
          this.receiveSchduleList(response);
          resolve();
        },
        error => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveSchduleList(response: any) {
    if (this.httpBasic.handleAppError(response)) return;
    if (response.rows.length > 0) {
      this.form.get("inventoryStatus").setValue(response.rows[0].processStatus);
      this.form.get("inventoryDate").setValue(response.rows[0].actualInvDate);
      this.ctrlDisabledBtn(response.rows[0].processStatus);
    } else {
      this.form.get("inventoryStatus").setValue("");
      this.form.get("inventoryDate").setValue("");
      this.ctrlDisabledBtn('棚卸開始前'); // to disable csv button
    }
  }

  initCategoryList(): Promise<void> {
    return new Promise((resolve, reject) => {
      var request  = {
        access: { ...this.commonService.loginUser }
      }
      let subsc = this.httpBasic.generalRequest('CategoryListInit', request).subscribe(
        response => {
          subsc.unsubscribe();
          this.receiveCategories(response);
          resolve();
        },
        error => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveCategories(response: any) {
    if (this.httpBasic.handleAppError(response)) return;
    this.gmainClassList = response.gMainClassificationResult;
    this.gmidClassList = response.gMediumClassificationResult;
    this.gsmallClassList = response.gSmallClassificationResult;
    this.gsubClassList = response.gSubClassificationResult;
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.0-0'); // Format with thousand separators and no decimal places
  }
  formatDecimal(value: number): string {
    return this.decimalPipe.transform(value, '1.2-2'); // Format with thousand separators and two decimal places
  }

  async changeStoreCd() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    await this.getInvMonthLists(this.form.get('storeCd').value);
    await this.getScheduleLists();
    this.commonService.closeSpinner();
  }

  async changeSchedule() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.form.get('invMonth').setValue(this.getInventoryYearMonth());
    await this.getScheduleLists();
    this.commonService.closeSpinner();
  }

  // Disable csv import and export btn base in inventory status
  ctrlDisabledBtn(invStatus: string) {
    if (invStatus == '棚卸開始前') {
      this.isCsvImportBtnDisabled = true;
      this.isCsvExportBtnDisabled = true;
    } else if (invStatus == '店舗確定済' || invStatus == '本部確定済') {
      this.isCsvImportBtnDisabled = true;
      this.isCsvExportBtnDisabled = false;
    } else {
      this.isCsvImportBtnDisabled = false;
      this.isCsvExportBtnDisabled = false;
    }
  }

  getInventoryYearMonth() {
    let index = this.monthLists.findIndex(schedule => schedule.invScheduleId === this.form.get("inventoryScheduleId").value);
    return this.monthLists[index].invMonth;
  }

  onProductScan(val: string) {
    if (this.commonService.config.loginBarcode) {
      this.form.get('productCd').setValue(val);
      this.fnGetProductInfo(val);
    }
  }

  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined && data != null && data !== "") {
          this.form.get('productCd').setValue(data);
          this.fnGetProductInfo(data);
        }
      }
    )
  }

  async fnGetProductInfo(productCd: string) {
    try {
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      // Check product code is not match with the inventroy schedule category
      let isMatch = await this.fnCheckProductInfo();
      if (isMatch) {
        // Check and get product info
        this.form.get('productNm').setValue(""); // Clear remaining data
        await this.getProductName(productCd);
      } else {
        // Display the popup for not matching data
        this.commonService.openNotificationDialog(this.commonService.pageTitle, this.messageEntity.message[MessageID.MI100005], '閉じる');
      }
      this.commonService.closeSpinner();
    } catch (error) {
      this.commonService.closeSpinner();
      this.httpBasic.handleError(error);
    }
  }

  fnCheckProductInfo(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let request = {
        access: this.commonService.loginUser,
        cmpnyCdFv: this.cmpnyCd,
        storeCdFv: this.form.get('storeCd').value,
        inventoryIdFv: this.form.get('inventoryScheduleId').value,
        productCdFv: this.form.get('productCd').value
      };

      let subsc = this.httpBasic.generalRequest('Spiv00101CheckMatchInventoryProduct', request).subscribe(
        response => {
          subsc.unsubscribe();
          if (this.httpBasic.handleAppError(response)) {
            return;
          } else {
            resolve(response.matchFv);
          }
        },
        error => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  getProductName(productCd: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const request: any = {
        productCd: productCd,
        cmpyCd: this.cmpnyCd,
        storeCd: this.form.get('storeCd').value,
        inventoryScheduleId: this.form.get('inventoryScheduleId').value,
        inventoryStatus: this.form.get('inventoryStatus').value,
        access: { ...this.commonService.loginUser },
      };

      let subsc = this.httpBasic.generalRequest("GetProductName", request).subscribe(
        response => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.receiveProductName(response);
          resolve();
        },
        error => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveProductName(response: any) {
    if (response.normalError && response.normalError.length > 0 && response.normalError[0].controlID == "NOSTOREPRODUCTCNT") {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.messageEntity.message[MessageID.MI100004], '閉じる');
    } else if (response.normalError && response.normalError.length > 0 && response.normalError[0].controlID == "NOINVPRODUCTCNT") {
      const dialogRef = this.commonService.dialog.open(NotificationDialogComponent, {
        data: { 
          title: this.commonService.pageTitle, 
          message: this.messageEntity.message[MessageID.MI100003], 
          btnText: '閉じる' 
        }
      });
      dialogRef.componentInstance.onClick.subscribe(() => {
        this.goInventoryProductMaster();
      });
    } else if (this.httpBasic.handleAppError(response)) {
      return;
    } else if (response.rows.length > 0) {
      this.form.get('productNm').setValue(response.rows[0].productNm);
      this.doSearch(false);
    } else this.form.get('productNm').setValue("");
  }

  fnOnChangeMainClassCd() {
    this.form.get('gmidClassCd').setValue("");
    this.form.get('gsmallClassCd').setValue("");
    this.form.get('gsubClassCd').setValue("");
  }
  fnOnChangeMidClassCd(event:any) {
    this.form.get('gsmallClassCd').setValue("");
    this.form.get('gsubClassCd').setValue("");
    this.setParentGMidClass(event.target.value);
  }
  fnOnChangeSmallClassCd(event:any) {
    this.form.get('gsubClassCd').setValue("");
    this.setParentGSmallClass(event.target.value);
  }
  fnOnChangeSubClassCd(event:any) {
    this.gsubClassList.forEach((gsub: any) => {
      if (event.target.value == gsub.gSubClassCdFv) {
        this.setParentGSmallClass(gsub.gSmallClassCdFv);
      }
    })
  }

  setParentGMainClass(selectedCd: string) {
    this.gmainClassList.forEach((gmain: any) => {
      if (selectedCd == gmain.gMainClassCdFv) {
        this.form.get('gmainClassCd').setValue(selectedCd);
      }
    });
  }
  setParentGMidClass(selectedCd: string) {
    this.gmidClassList.forEach((gmid: any) => {
      if (selectedCd == gmid.gMiddleClassCdFv) {
        this.form.get('gmidClassCd').setValue(selectedCd);
        this.setParentGMainClass(gmid.gMainClassCdFv);
      }
    })
  }
  setParentGSmallClass(selectedCd: string) {
    this.gsmallClassList.forEach((gsmall: any) => {
      if (selectedCd == gsmall.gSmallClassCdFv) {
        this.form.get('gsmallClassCd').setValue(selectedCd);
        this.setParentGMidClass(gsmall.gMiddleClassCdFv);
      }
    })
  }

  doSearch(isBtnClick: boolean) {
    this.productCdMessage = ""; // Clear error message
    // Check validate the input fields before process
    if (this.form.valid) {
      let request = this.fnSearchRequest();
      if (isBtnClick) {
        this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      }
      let subsc = this.httpBasic.generalRequest("Spiv00051Search", request).subscribe(
        (response: RspSpiv00051Search) => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.receiveInventoryEntryList(response);
        },
        (error) => {
          subsc.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
        }
      );
    } else {
      this.commonService.closeSpinner();
      this.validateFormFields(this.form);
    }
  }

  receiveInventoryEntryList(response: RspSpiv00051Search) {
    this.inventoryEntryList = [];
    this.matPaginator.pageIndex = 0; // reset page

    if (response.normalError && response.normalError.length > 0 && response.normalError[0].controlID == "productCd") {
      this.productCdMessage = response.normalError[0].errMsg;
      return;
    } else if (this.httpBasic.handleAppError(response)) { 
      return;
    } else {
      // Check result list count
      if (response.rows.length > 0) {
        for (var data of response.rows) {
          let tmp = {
            ...data,
            inventoryQty: this.formatNumber(data.inventoryQty),
            stockQty: this.formatNumber(data.stockQty),
            posPrice: this.formatDecimal(data.posPrice),
            salePrice: this.formatDecimal(data.salePrice),
            posPriceAmt: this.formatDecimal(data.inventoryQty * data.posPrice),
            salePriceAmt: this.formatDecimal(data.inventoryQty * data.salePrice),
            gmainClass: data.gmainClassCd +':'+ data.gmainClassNm,
            gmidClass: data.gmainClassCd +'-'+ data.gmidClassCd +':'+ data.gmidClassNm,
            gsmallClass: data.gmainClassCd +'-'+ data.gmidClassCd +'-'+ data.gsmallClassCd +':'+ data.gsmallClassNm,
            gsubClass: data.gmainClassCd +'-'+ data.gmidClassCd +'-'+ data.gsmallClassCd +'-'+ data.gsubClassCd +':'+ data.gsubClassNm,
          }
          this.inventoryEntryList.push(tmp);
        }
      }
    }
    this.recordCount = this.inventoryEntryList?.length ?? 0;
    this.pageChanged();
  }

  fnSearchRequest() {
    let request: ReqSpiv00051Search = {
      access: this.commonService.loginUser,
      cmpnyCd: this.cmpnyCd,
      storeCd: this.form.get("storeCd").value ?? "",
      inventoryScheduleId: this.form.get("inventoryScheduleId").value ?? "",
      inventoryYearMonth: this.getInventoryYearMonth(),
      inventoryStatus: this.form.get("inventoryStatus").value ?? "",
      inventoryDate: this.form.get("inventoryDate").value ?? "",
      productCd: this.form.get("productCd").value ?? "",
      productNm: this.form.get("productNm").value ?? "",
      gmainClassCd: this.form.get("gmainClassCd").value ?? "",
      gmidClassCd: this.form.get("gmidClassCd").value ?? "",
      gsmallClassCd: this.form.get("gsmallClassCd").value ?? "",
      gsubClassCd: this.form.get("gsubClassCd").value ?? "",
      inventoryQtyFrom: parseInt(this.form.get("inventoryQtyFrom").value ?? ""),
      inventoryQtyTo: parseInt(this.form.get("inventoryQtyTo").value ?? ""),
      stockQtyFrom: parseInt(this.form.get("stockQtyFrom").value ?? ""),
      stockQtyTo: parseInt(this.form.get("stockQtyTo").value ?? ""),
      isDiffQtyCheck: this.isDiffQtyCheck.value,
      isNoDiffQtyCheck: this.isNoDiffQtyCheck.value,
    };
    return request;
  }

  // Check validation before search process
  validateFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  goInventoryProductMaster() {
    this.commonService.fpiv0005SearchData = {
      storeCd: this.form.get('storeCd').value,
      invScheduleId: this.form.get('inventoryScheduleId').value,
      invYearMonth: this.form.get('invMonth').value,
      productCd: this.form.get('productCd').value,
      productNm: this.form.get('productNm').value,
      gmainClassCd: this.form.get('gmainClassCd').value,
      gmidClassCd: this.form.get('gmidClassCd').value,
      gsmallClassCd: this.form.get('gsmallClassCd').value,
      gsubClassCd: this.form.get('gsubClassCd').value,
      inventoryQtyFrom: this.form.get('inventoryQtyFrom').value,
      inventoryQtyTo: this.form.get('inventoryQtyTo').value,
      stockQtyFrom: this.form.get('stockQtyFrom').value,
      stockQtyTo: this.form.get('stockQtyTo').value,
    }
    this.invProductDialog = this.dialog.open(Spiv00101Component, {});
    this.invProductDialog.componentInstance.fpiv0101Exist.subscribe(() => {
      let srhData = this.commonService.fpiv0005SearchData;
      this.commonService.fpiv0005SearchData = undefined;
      this.form.get('productNm').setValue(srhData.productNm ?? "");
      this.doSearch(true);
    });
  }

  doCancel() {
    this.clearInputs();
    this.inventoryEntryList = [];
    this.matPaginator.pageIndex = 0; // reset page
    this.pageChanged();
  }

  clearInputs() {
    this.form.controls['productCd'].setValue("");
    this.form.controls['productNm'].setValue("");
    this.form.controls['gmainClassCd'].setValue("");
    this.form.controls['gmidClassCd'].setValue("");
    this.form.controls['gsmallClassCd'].setValue("");
    this.form.controls['gsubClassCd'].setValue("");
    this.form.controls['inventoryQtyFrom'].setValue("");
    this.form.controls['inventoryQtyTo'].setValue("");
    this.form.controls['stockQtyFrom'].setValue("");
    this.form.controls['stockQtyTo'].setValue("");
    this.isDiffQtyCheck.setValue(true);
    this.isNoDiffQtyCheck.setValue(true);
    this.productCdMessage = "";
  }

  pageChanged() {
    let start;
    let end;
    this.inventoryEntryListDisplay.data = [];
    this.recordCount = this.inventoryEntryList?.length ?? 0;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize
      end = start + this.matPaginator.pageSize;
    } else {
      start = 0;
      end = this.commonService.config.pagenatorOptions[0];
    }
    if (end > this.recordCount) end = this.recordCount;
    if (this.inventoryEntryListDisplay?.data.length == 1) return;
    else this.inventoryEntryListDisplay.data = this.inventoryEntryList?.slice(start, end);
  }

  // Send row data to detail screen
  onClickCell(columnId: string, items: any) {
    if (columnId === "inventoryQty") {
      this.commonService.fpiv0005RowData = []; // Clear data before the data setter
      // Set row data in shared service
      this.commonService.fpiv0005SearchData = {
        storeCd: this.form.get('storeCd').value,
        invScheduleId: this.form.get('inventoryScheduleId').value,
        invYearMonth: this.form.get('invMonth').value,
        invStatus: this.form.get('inventoryStatus').value,
        productCd: this.form.get('productCd').value,
        productNm: this.form.get('productNm').value,
        gmainClassCd: this.form.get('gmainClassCd').value,
        gmidClassCd: this.form.get('gmidClassCd').value,
        gsmallClassCd: this.form.get('gsmallClassCd').value,
        gsubClassCd: this.form.get('gsubClassCd').value,
        inventoryQtyFrom: this.form.get('inventoryQtyFrom').value,
        inventoryQtyTo: this.form.get('inventoryQtyTo').value,
        stockQtyFrom: this.form.get('stockQtyFrom').value,
        stockQtyTo: this.form.get('stockQtyTo').value,
        cmpnyCd: this.cmpnyCd
      }
      this.commonService.fpiv0005RowData = [{...items}];
      this.commonService.fpiv0011ParentPage = 'fpiv0005';
      this.detailDialog = this.dialog.open(Spiv00111Component, {});
      this.detailDialog.componentInstance.fpiv0011Exist.subscribe(() => {
        this.doSearch(true);
      });
    }
  }

  // If negative value, change text color to red
  isNegativeVal(value): boolean {
    if (parseInt(value) < 0) return true;
    return false;
  }

  // if the progess status is 棚卸実施中, text color is default, otherwise, red text
  checkStatus(): boolean {
    return this.form.get('inventoryStatus').value === '棚卸実施中' ? false : true;
  }

  doExportCsv() {
    let params = {
      "searchCondition": this.fnSearchRequest(),
      "searchWebService": 'Spiv00051Search',
      "functionId": 'FPIV0005',
      "screenId": 'SPIV00051',
      "screenTitle": this.commonService.pageTitle,
      "cmpnyCd": this.cmpnyCd,
      "lang": this.lang
    };
    this.csvExportDialog = this.dialog.open(CsvExportComponent, {data: params});
  }

  doExit() {
    // Filter the parent page
    if (this.parentPage === 'fpiv0008') {
      this.commonService.fpiv0008ParentPage = 'fpiv0005';
      this.commonService.pageMenuName = "棚卸差異確認リスト";
      this.commonService.fpiv0008SearchData = this.fpiv0008SearchData;
      this.router.navigate(['/SPIV00081']);
    } else if (this.parentPage === 'fpiv0009') {
      this.commonService.fpiv0009ParentPage = 'fpiv0005';
      this.commonService.pageMenuName = "未棚卸確認リスト";
      this.commonService.fpiv0009SearchData = this.fpiv0009SearchData;
      this.router.navigate(['/SPIV00091']);
    }
  }

}
