import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import {
  ME200002,
  ME200004,
  ME200005,
  ME200007,
  ME200008,
  MI100001,
  MI200001,
  MI200002,
  MQ200002,
  MQ200006,
  MQ200010,
  MQ200011,
} from "src/app/common/const-message-id";
import { TableColumnDef } from "src/app/common/table-column-def";
import { BarcodeDialogComponent } from "src/app/dialog/barcode-dialog/barcode-dialog.component";
import { ErrorNotificationDialogComponent } from "src/app/dialog/error-notification-dialog/error-notification-dialog.component";
import { Spmt10261DetailDialogComponent } from "src/app/dialog/spmt10261-detail-dialog/spmt10261-detail-dialog.component";
import { Spmt10261SlipListDialogComponent } from "src/app/dialog/spmt10261-slip-list-dialog/spmt10261-slip-list-dialog.component";
import { PositiveNumberOnlyError } from "src/app/directive/positive-number-only.directive";
import { NumberKeypadComponent } from "src/app/partsCommon/number-keypad/number-keypad.component";
import { Req } from "src/app/request/req";
import { ReqAccess } from "src/app/request/req-access";
import { ReqGetStoreList } from "src/app/request/req-get-store-list";
import { ReqSpmt10261Confirm } from "src/app/request/req-spmt10261-confirm";
import { ReqSpmt10261Delete } from "src/app/request/req-spmt10261-delete";
import { ReqSpmt10261Search, ReqSpmt10261SearchDto } from "src/app/request/req-spmt10261-search";
import { Rsp } from "src/app/response/rsp";
import { RspSpmt10261Delete } from "src/app/response/rsp-spmt10261-delete";
import { RspSpmt10261Search, RspSpmt10261SearchDto } from "src/app/response/rsp-spmt10261-search";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";
import { MessageService } from "src/app/service/message.service";

const MOVE_OUT_REQUESTING_STATUS = "0";
const DEL_FLG = "1";
const MOVE_OUT_APPROVED_STATUS = "1";
const RED_FLG = "02";

interface CustomReqAccess extends ReqAccess {
  cmpnyCd: string;
  lang: string;
  sysModeCd: string;
}

interface StoreSearchData {
  slipDtlNo: string;
  outStockQty: number;
  slipDtlComment: string;
}

interface CustomTableColumnDef extends TableColumnDef {
  type?: "normal" | "checkbox" | "inputText" | "inputNumber" | "link" | "sum" | "number" | "price";
  isDisable?: Function;
}
@Component({
  selector: "app-spmt10261",
  templateUrl: "./spmt10261.component.html",
  styleUrls: ["./spmt10261.component.css"],
})
export class Spmt10261Component implements OnInit, AfterViewInit, AfterViewChecked {
  constructor(
    private formBuilder: FormBuilder,
    private httpBasic: HttpBasicService,
    public commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private message: MessageService
  ) {
    this.fnInitFormGroup();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  get MOVE_OUT_APPROVED_STATUS() {
    return MOVE_OUT_APPROVED_STATUS;
  }

  get RED_FLG() {
    return RED_FLG;
  }

  @ViewChild("keypad", { static: true }) numberKeypadComponent: NumberKeypadComponent;
  @ViewChild("searchProductCode", { static: true }) searchProductCode: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  access: CustomReqAccess = {
    ...this.commonService.loginUser,
    cmpnyCd: "",
    lang: "",
    storeCd: "",
    sysModeCd: "",
  };
  moveList = new MatTableDataSource<RspSpmt10261SearchDto>([]);
  searchInitFlg: boolean = true;
  searchConditionForm: FormGroup;
  detailForm: FormGroup;
  listStore: any[] = [];
  allStoreList: any[] = [];

  initSearchCondition: ReqSpmt10261SearchDto = {
    outStoreCd: this.access.storeCd,
    slipNo: "",
    productCd: "",
  };

  storeSearchCondition: ReqSpmt10261SearchDto = {
    ...this.initSearchCondition,
  };

  storeSearchData: StoreSearchData[] = [];

  initDetailForm: any = {
    reqStatus: "",
    reqStatusNm: "",
    slipNo: "",
    inStoreCd: "",
    outStoreCd: "",
    slipComment: "",
    approverComment: "",
    updDateTime: "",
  };

  checkAllFlag: boolean = false;
  isDisabledDelBtn: boolean = true;
  keypadTargetId: string;
  sectionConditionOpen: boolean = true;
  sectionListOpen: boolean = true;

  /**
   * Retrieves access information by making a request to the server.
   *
   * @return {void}
   */
  private fnGetAccessInfo(): void {
    // Cant login, hard code userId
    let req: Req = {
      access: this.access,
    };
    this.httpBasic.beforeRequestStart("読み込み中...");
    this.httpBasic.getUserInfo(req).subscribe({
      next: (res) => {
        if (this.httpBasic.handleAppError(res)) return;
        if (res.rows.length > 0) {
          this.access.cmpnyCd = res.rows[0].mainCmpnyCd;
          this.access.storeCd = res.rows[0].mainStoreCd;
          this.access.sysModeCd = res.rows[0].sysModeCd;
          this.access.lang = res.rows[0].mainLang;
          this.initSearchCondition.outStoreCd = res.rows[0].mainStoreCd;
          this.initDetailForm.inStoreCd = res.rows[0].mainStoreCd;
          this.searchConditionForm.patchValue({
            outStoreCd: res.rows[0].mainStoreCd,
          });
          this.detailForm.patchValue({
            inStoreCd: res.rows[0].mainStoreCd,
          });
          this.fnInitScreen();
        }
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => this.httpBasic.afterRequestComplete(),
    });
  }

  private fnInitScreen(): void {
    this.getLoginStoreLists();
    this.getAllStoreLists();
  }

  getLoginStoreLists() {
    const request: ReqGetStoreList = {
      storeCd: "",
      userId: this.access.userId,
      cmpyCd: this.access.cmpnyCd,
      sysModeCd: this.access.sysModeCd,
      access: this.access,
    };
    this.httpBasic.beforeRequestStart("読み込み中...");
    this.httpBasic.getStoreList(request).subscribe({
      next: (res) => {
        if (this.httpBasic.handleAppError(res)) return;
        this.listStore = res.rows;
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => this.httpBasic.afterRequestComplete(),
    });
  }

  getAllStoreLists() {
    let request: Req = {
      access: this.access,
    };
    this.httpBasic.beforeRequestStart("読み込み中...");
    this.httpBasic.generalRequest("Spmt10261Init", request).subscribe({
      next: (res) => {
        if (this.httpBasic.handleAppError(res)) return;
        this.allStoreList = res.allStoreList;
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => this.httpBasic.afterRequestComplete(),
    });
  }

  fnIsDisableCheckboxAll(): boolean {
    return this.moveList.data.every((x) => x.disabledCheckbox);
  }

  private fnSetDisabledValue(row: RspSpmt10261SearchDto): void {
    const isApproved = row.reqStatus === MOVE_OUT_APPROVED_STATUS;
    const isBiggerThanApproved = row.reqStatus > MOVE_OUT_APPROVED_STATUS;
    const isRed = row.redBlackFlg === RED_FLG;
    const hasOutCancelDate = row.outCancelDate;
    const hasRequestingStatus = this.moveList.data.some((x) => x.productCd === row.productCd && x.reqStatus === MOVE_OUT_REQUESTING_STATUS);

    row.disabled = isRed || (isApproved && (hasOutCancelDate || hasRequestingStatus)) || isBiggerThanApproved;
    row.disabledCheckbox =
      isBiggerThanApproved || (isRed && (isApproved || isBiggerThanApproved)) || (isApproved && (hasOutCancelDate || hasRequestingStatus));
  }

  ngOnInit(): void {
    this.access = {
      ...this.access,
      ...this.commonService.loginUser,
    };
    this.searchProductCode.nativeElement.focus();
    this.commonService.pageTitle = "店間移動出庫申請";

    this.fnGetAccessInfo();
  }

  ngAfterViewInit() {
    this.moveList.paginator = this.paginator;
  }

  private fnClear(): void {
    this.moveList.data = [];
    this.checkAllFlag = false;
    this.searchInitFlg = true;
    this.isDisabledDelBtn = true;
    this.storeSearchCondition = {
      ...this.initSearchCondition,
    };
    this.searchConditionForm.patchValue(this.initSearchCondition);
    this.detailForm.patchValue(this.initDetailForm);
    this.keypadTargetId = "productCd";
    this.numberKeypadComponent.setTargetForm(this.searchConditionForm.get("productCd") as FormControl);
  }

  showErrorMoveQty(inputNum: HTMLInputElement, $event: PositiveNumberOnlyError, row: RspSpmt10261SearchDto) {
    if ($event.minValue || $event.maxValue) {
      let message = this.message.message[ME200005]?.replace("%1", "出庫数量").replace("%2", "1").replace("%3", "99999");
      this.fnShowError(inputNum, message);
    } else if ($event.optionMaxValue) {
      this.fnShowError(inputNum, this.message.message[ME200004]?.replace("%1", "出庫数量").replace("%2", "在庫数"));
    }
  }

  private fnShowError(el: HTMLInputElement, errorMessage: string) {
    el.blur();

    const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
      data: {
        errorMessage: errorMessage,
        errorTitle: this.commonService.pageTitle,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      el.focus();
      el.setSelectionRange(el.value.length, el.value.length);
    });
  }

  onClickLink(item: RspSpmt10261SearchDto): void {
    const dialogRef = this.commonService.dialog.open(Spmt10261DetailDialogComponent, {
      data: {
        product: { ...item, outStockQty: item.outStockQtyForm.value },
        viewMode: item.disabled,
        btnTitle: "更新",
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.moveList.data = this.moveList.data.map((product) => {
          if (product.productCd === data.productCd && product.slipDtlNo === data.slipDtlNo) {
            product.outStockQtyForm.setValue(data.outStockQty);
            return {
              ...data,
              outStockQtyForm: product.outStockQtyForm,
            };
          }
          return product;
        });
      }
    });
  }

  onChangeRowCheck(item: RspSpmt10261SearchDto, event: any) {
    item.deleteFlg = event.checked;
    if (item.reqStatus === MOVE_OUT_REQUESTING_STATUS) {
      let found = this.moveList.data.find(
        (row) => item.productCd === row.productCd && row.slipDtlNo !== item.slipDtlNo && row.reqStatus == MOVE_OUT_REQUESTING_STATUS
      );
      if (found) found.deleteFlg = item.deleteFlg;
    }
    this.checkAllFlag = this.moveList.data.length > 0 && this.moveList.data.filter((item) => !item.disabledCheckbox).every((item) => item.deleteFlg);
    this.isDisabledDelBtn = this.fnIsDisableDeleteButton();
  }

  checkAll($event: any): void {
    this.checkAllFlag = $event.checked;

    this.moveList.data.forEach((item) => {
      if (!item.disabledCheckbox) item.deleteFlg = $event.checked;
    });
    this.isDisabledDelBtn = this.fnIsDisableDeleteButton();
  }

  btnDeleteClick(): void {
    if (!this.isDisabledDelBtn) {
      const dialogRef = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200002]);
      dialogRef.subscribe((result) => {
        if (result) {
          this.fnDeleteList();
        }
      });
    }
  }

  private fnIsDisableDeleteButton(): boolean {
    const res = this.moveList.data.length == 0 || this.moveList.data.filter((item) => item.deleteFlg).length == 0;

    return res;
  }

  private fnDeleteList(): void {
    this.checkAllFlag = false;

    if (this.detailForm.value.slipNo) {
      const deleteList = this.fnSetDeleteList(this.moveList.data);
      if (deleteList.length > 0) {
        const request = this.fnGetRequestForDelete(deleteList);
        this.httpBasic.beforeRequestStart("削除中...");
        this.httpBasic.spmt10261Delete(request).subscribe({
          next: (response: RspSpmt10261Delete) => {
            if (this.httpBasic.handleAppError(response)) return;
            this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI200001]);

            this.moveList.data = this.moveList.data.filter((item) => !item.deleteFlg);
            if (response.delFlg === DEL_FLG) {
              this.fnClear();
            } else {
              this.searchAfterDelete();
            }
          },
          error: (err) => {
            this.httpBasic.afterRequestError(err);
          },
          complete: () => {
            this.httpBasic.afterRequestComplete();
          },
        });
      }
    } else {
      this.moveList.data = this.moveList.data.filter((item) => !item.deleteFlg);

      if (this.moveList.data.length === 0) {
        this.fnClear();
      }
    }
  }

  private searchAfterDelete() {
    const request: ReqSpmt10261Search = {
      access: this.access,
      outStoreCd: this.detailForm.value.outStoreCd,
      slipNo: this.detailForm.value.slipNo,
      productCd: "",
    };
    this.httpBasic.beforeRequestStart();
    this.httpBasic.spmt10261SlipSearch(request).subscribe({
      next: (response: RspSpmt10261Search) => {
        if (this.httpBasic.handleAppError(response)) return;
        if (response.rows.length === 0) {
          return;
        }
        this.moveList.data = this.mergeArrays(this.moveList.data, response.rows);
        this.moveList.data.forEach((item) => {
          this.fnSetDisabledValue(item);
        });
        this.fnSetHeaderInfo();
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => {
        this.httpBasic.afterRequestComplete();
      },
    });
  }

  /**
   * Merges two arrays of RspSpmt10261SearchDto objects, combining items with matching slipDtlNo values.
   */
  private mergeArrays(array1: RspSpmt10261SearchDto[], array2: RspSpmt10261SearchDto[]): RspSpmt10261SearchDto[] {
    let result = [...array1.filter((item) => !item.slipDtlNo), ...array2].map((item) => {
      let found = array1.find((row) => row.slipDtlNo === item.slipDtlNo);
      if (found) {
        return {
          ...item,
          outStockQty: found.outStockQty,
          slipDtlComment: found.slipDtlComment,
          outStockQtyForm: found.outStockQtyForm,
          slipComment: this.detailForm.value.slipComment,
        };
      }
      return { ...item, outStockQtyForm: new FormControl(item.outStockQty), slipComment: this.detailForm.value.slipComment };
    });
    return result;
  }

  use10KeyPad(): boolean {
    return this.commonService.config.use10KeyPad;
  }

  isKeyboardLocked(): boolean | null {
    if (!this.use10KeyPad()) return null;
    if (this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  private fnGetRequestForDelete(deleteList: RspSpmt10261SearchDto[]): ReqSpmt10261Delete {
    return {
      access: this.access,
      deleteList: deleteList,
      outStoreCd: this.searchConditionForm.get("outStoreCd").value,
      ...this.detailForm.value,
    };
  }

  private fnSetDeleteList(data: RspSpmt10261SearchDto[]): RspSpmt10261SearchDto[] {
    return data.filter((item) => item.deleteFlg && item.slipDtlNo).map((item) => ({ ...item, outStockQtyForm: undefined }));
  }

  private fnInitFormGroup(): void {
    this.searchConditionForm = this.formBuilder.group(
      {
        outStoreCd: [this.initSearchCondition.outStoreCd, [Validators.required]],
        productCd: [this.initSearchCondition.productCd],
        slipNo: [this.initSearchCondition.slipNo],
      },
      {
        validators: this.requiredOne("productCd", "slipNo"),
      }
    );
    this.detailForm = this.formBuilder.group({
      ...this.initDetailForm,
      inStoreCd: [this.initDetailForm.inStoreCd, [Validators.required]],
      reqStatus: [this.initDetailForm.reqStatus, this.reqStatusMustBeLessThan(MOVE_OUT_APPROVED_STATUS)],
    });
  }

  private reqStatusMustBeLessThan(max: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error = control.value > max ? { reqStatusMustBeLessThan: true } : null;
      return error;
    };
  }

  private requiredOne(...control: string[]): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      for (const ctrl of control) {
        if (formGroup.get(ctrl).value !== "") {
          return null;
        }
      }
      return {
        allEmpty: true,
      };
    };
  }

  set10KeyPadTarget(control: AbstractControl, id: string, maxCol?: number, stringMode?: boolean) {
    if (!this.commonService.config.use10KeyPad) {
      return;
    }
    this.numberKeypadComponent.setTargetForm(control as FormControl);
    if (maxCol) this.numberKeypadComponent.setMaxCol(maxCol);
    if (stringMode) {
      this.numberKeypadComponent.setStringMode();
    }
    this.keypadTargetId = id;
  }

  onScan(barcode: string): void {
    if (barcode) {
      this.fnExecAfterScan(barcode);
    }
  }

  private fnExecAfterScan(barcode: string) {
    let productCd = barcode;
    if (this.commonService.config.loginBarcode) {
      productCd = barcode.substring(this.commonService.config.loginBarcode.start, this.commonService.config.loginBarcode.end);
    }
    this.searchConditionForm.get("productCd").setValue(productCd);
    if (productCd) {
      const request = this.fnGetRequestForSearch();
      this.fnSearchProduct(request);
    }
  }

  openBarcodeDialog(): void {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.fnExecAfterScan(data);
      }
    });
  }

  btnListSlipNoClick(): void {
    const dialogRef = this.commonService.dialog.open(Spmt10261SlipListDialogComponent, {
      data: {
        access: this.access,
        outStoreCode: this.searchConditionForm.get("outStoreCd").value,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.searchConditionForm.get("slipNo").setValue(data.slipNo);
        this.btnSearchClick();
      }
    });
  }

  btnSearchClick(): void {
    if (this.searchConditionForm.valid) {
      if (
        this.moveList.data.length > 0 &&
        (this.searchConditionForm.value.outStoreCd != this.storeSearchCondition.outStoreCd ||
          this.searchConditionForm.value.slipNo != this.storeSearchCondition.slipNo)
      ) {
        this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200011]).subscribe((result) => {
          if (result) {
            this.moveList.data = [];
            this.detailForm.patchValue(this.initDetailForm);
            this.searchInitFlg = true;
            this.fnSearch();
          }
        });
      } else {
        this.fnSearch();
      }
    }
  }

  private fnSearch(): void {
    const request = this.fnGetRequestForSearch();
    if (request.slipNo && (this.searchInitFlg || !request.slipNo)) {
      this.fnSearchSlip(request);
    } else if (request.productCd) {
      this.fnSearchProduct(request);
    }
  }

  private fnSearchSlip(request: ReqSpmt10261Search): void {
    this.httpBasic.beforeRequestStart("検索中...");
    this.httpBasic.spmt10261SlipSearch(request).subscribe({
      next: (response: RspSpmt10261Search) => {
        if (this.httpBasic.handleAppError(response)) return;
        if (response.rows.length === 0) {
          this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001]);
          return;
        }
        this.moveList.data = response.rows.map((row) => ({ ...row, outStockQtyForm: new FormControl(row.outStockQty) }));
        this.fnProcessAfterSearchSuccess();
        if (request.productCd) {
          this.fnSearchProduct(request);
        }
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => {
        this.httpBasic.afterRequestComplete();
      },
    });
  }

  private fnSearchProduct(request: ReqSpmt10261Search): void {
    if (
      this.detailForm.value.reqStatus &&
      this.detailForm.value.reqStatus !== MOVE_OUT_REQUESTING_STATUS &&
      this.detailForm.value.reqStatus !== MOVE_OUT_APPROVED_STATUS
    ) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.message.message[ME200008]);
      return;
    }
    const productCode = this.searchConditionForm.value.productCd;
    const existingProductIndex = this.moveList.data.findIndex((item) => item.productCd === productCode);

    if (existingProductIndex !== -1) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.message.message[ME200007]?.replace("%1", "出庫一覧"));
      return;
    }
    this.httpBasic.beforeRequestStart("検索中...");
    this.httpBasic.spmt10261ProductSearch(request).subscribe({
      next: (response: RspSpmt10261Search) => {
        this.fnHandleSearchResponse(response);
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => {
        this.httpBasic.afterRequestComplete();
      },
    });
  }

  private fnHandleSearchResponse(response: RspSpmt10261Search): void {
    if (this.httpBasic.handleAppError(response)) return;
    if (response.rows.length === 0) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001]);
      return;
    }
    this.fnShowPopupStockInfo(response);
  }

  private fnProcessAfterSearchSuccess(): void {
    if (this.searchInitFlg && this.searchConditionForm.value.slipNo) {
      this.storeSearchData = this.moveList.data
        .filter((item) => item.reqStatus === MOVE_OUT_APPROVED_STATUS && !item.disabled)
        .map((item) => {
          return {
            slipDtlNo: item.slipDtlNo,
            outStockQty: item.outStockQty,
            slipDtlComment: item.slipDtlComment,
          };
        });
      this.moveList.data.forEach((item) => {
        this.fnSetDisabledValue(item);
      });
      this.fnSetHeaderInfo();
    }
    this.moveList._updateChangeSubscription();
    this.storeSearchCondition = {
      ...this.searchConditionForm.value,
    };
    this.checkAllFlag = false;
    this.fnClearTargetForm();
    this.searchInitFlg = false;
  }

  fnClearTargetForm() {
    this.keypadTargetId = "";
    this.numberKeypadComponent.setTargetForm(undefined);
  }

  private fnSetHeaderInfo(): void {
    let sorted = [...this.moveList.data].sort((a, b) => {
      return a.reqStatus.localeCompare(b.reqStatus);
    });
    this.detailForm.patchValue({
      reqStatus: sorted[0].reqStatus,
      reqStatusNm: sorted[0].reqStatusNm,
      slipNo: sorted[0].slipNo,
      inStoreCd: sorted[0].inStoreCd,
      outStoreCd: sorted[0].outStoreCd,
      slipComment: sorted[0].slipComment,
      approverComment: sorted[0].approverComment || "",
      updDateTime: sorted[0].headerUpdDateTime,
    });
  }

  private fnShowPopupStockInfo(data: RspSpmt10261Search): void {
    let dialogRef = this.commonService.dialog.open(Spmt10261DetailDialogComponent, {
      data: {
        product: data.rows[0],
        btnTitle: "追加",
      },
    });

    dialogRef.afterClosed().subscribe((data: RspSpmt10261SearchDto) => {
      if (data) {
        this.fnSetDisabledValue(data);
        this.moveList.data = [{ ...data, outStockQtyForm: new FormControl(data.outStockQty) }, ...this.moveList.data];
        this.fnProcessAfterSearchSuccess();
        this.cdr.detectChanges();
        let td = document.getElementById("outStockQtyForm0") as HTMLTableCellElement;
        td.getElementsByTagName("input")[0].focus();
      }
    });
  }

  private fnGetRequestForSearch(): ReqSpmt10261Search {
    let condition: ReqSpmt10261SearchDto = this.searchConditionForm.value;
    return {
      access: this.access,
      ...condition,
    };
  }

  btnConfirmClick(): void {
    if (this.detailForm.valid && this.moveList.data.length > 0) {
      if (this.searchConditionForm.value.outStoreCd === this.detailForm.value.inStoreCd) {
        this.commonService.openErrorDialog(this.commonService.pageTitle, this.message.message[ME200002]);
        return;
      }
      const dialogRef = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200006]);
      dialogRef.subscribe((data) => {
        if (data) {
          this.fnConfirm();
        }
      });
    }
  }

  private fnConfirm(): void {
    let request: ReqSpmt10261Confirm = this.fnGetRequestForRegistUpdate();
    this.httpBasic.beforeRequestStart("登録中・・・");
    this.httpBasic.spmt10261Confirm(request).subscribe({
      next: (data: Rsp) => {
        if (this.httpBasic.handleAppError(data)) return;
        this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI200002]);
        this.fnClear();
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => {
        this.httpBasic.afterRequestComplete();
      },
    });
  }

  private fnGetRequestForRegistUpdate(): ReqSpmt10261Confirm {
    const changeRows = this.getChangeRowsForConfirm();
    return {
      access: this.access,
      ...this.detailForm.value,
      outStoreCd: this.searchConditionForm.get("outStoreCd").value,
      rows: changeRows,
    };
  }

  private getChangeRowsForConfirm(): ReqSpmt10261Confirm["rows"] {
    const changeRows: ReqSpmt10261Confirm["rows"] = [];
    const activeRows = this.moveList.data
      .filter((row) => row.disabled === false)
      .map((row) => ({ ...row, outStockQty: row.outStockQtyForm.value, outStockQtyForm: undefined }));
    for (const row of activeRows) {
      const matchingRow = this.storeSearchData.find((dataRow) => dataRow.slipDtlNo === row.slipDtlNo);

      if (matchingRow && matchingRow.outStockQty !== row.outStockQty) {
        changeRows.push({ ...row, ...matchingRow });
        row.changeForSlipDtlNo = row.slipDtlNo;
        row.slipDtlNo = undefined;
        changeRows.push(row);
        continue;
      }
      changeRows.push(row);
    }

    return changeRows;
  }

  btnClearClick(): void {
    if (this.moveList.data.length === 0) {
      this.fnClear();
      return;
    }
    const dialogRef = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200010]);
    dialogRef.subscribe((res) => {
      if (res) {
        this.fnClear();
      }
    });
  }

  columnIds: string[] = [
    "deleteFlg",
    "productCd",
    "productNm",
    "standardNm",
    "outStockQtyForm",
    "logicStockQty",
    "reqStatusNm",
    "sellPriceExc",
    "totalPrice",
    "slipDtlComment",
  ];

  columnDefs: CustomTableColumnDef[] = [
    {
      columnId: "deleteFlg",
      header: "削除",
      width: 45,
      align: "center",
      checkbox: true,
      type: "checkbox",
    },
    { columnId: "productCd", header: "商品コード", width: 95, align: "left", type: "link" },
    { columnId: "productNm", header: "商品名", width: 200, align: "left" },
    { columnId: "standardNm", header: "規格", width: 95, align: "left" },
    {
      columnId: "outStockQtyForm",
      header: "出庫数量",
      width: 70,
      align: "right",
      type: "inputNumber",
    },
    { columnId: "logicStockQty", header: "在庫数", width: 70, align: "right", type: "number" },
    { columnId: "reqStatusNm", header: "申請状態", width: 75, align: "left" },
    { columnId: "sellPriceExc", header: "売価", width: 60, align: "right", type: "price" },
    { columnId: "totalPrice", header: "売価金額合計", width: 80, align: "right", type: "sum" },
    {
      columnId: "slipDtlComment",
      header: "詳細コメント",
      width: 200,
      align: "left",
      type: "inputText",
    },
  ];
}
