import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Spmt10321Dto } from 'src/app/response/rsp-spmt10321-search';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqSpmt10321GetDetail } from 'src/app/request/req-spmt10321-get-detail';
import { Spmt10321GetDetailDto, RspSpmt10321GetDetail } from 'src/app/response/rsp-spmt10321-get-detail';
import { Spmt10321Component } from 'src/app/page/spmt10321/spmt10321.component';
import { ReqAccess } from 'src/app/request/req-access';
import { Req } from 'src/app/request/req';
import { MatPaginator } from '@angular/material/paginator';
import { MessageService } from 'src/app/service/message.service';
import { MI100001, MI200003, MI200010 } from 'src/app/common/const-message-id';
import { ReqPageDto } from 'src/app/request/req-page-dto';

class DTADetailDialogConstant{
  public static readonly MESSAGESEARCHING = '検索中・・・';
  public static readonly EMPTY_STRING = '';
  public static readonly MAXITEMS = 1000;
  public static readonly LIMIT_RECORD = 1001;
  public static readonly ZERO = 0;
  public static readonly REQSTATUS_INIT = '申請中';
  public static readonly REDBLACKFLG_02: string = '02' // red
}

@Component({
  selector: 'spmt10321-detail-dialog',
  templateUrl: './spmt10321-detail-dialog.component.html',
  styleUrls: ['./spmt10321-detail-dialog.component.css']
})
export class Spmt10321DetailDialogComponent implements OnInit {

  public columnIdFirst: string[] = ["productCd","standardNm","dtQty","redBlackFlgNm","reqStatusNm","orderPriceExc","posCostExc","sellPriceExc","reqDate","approveDate","cancelDate","slipDtlComment","systemMsg"];
  public columnIdSecond: string[] = ["productNm","totalOrderPriceExc","totalPosCostExc","totalSellPriceExc"];

  public columnDefsItemsFirst: TableColumnDef[] = [
    {columnId: "productCd", header: "商品コード", width: 90, align: "left",rowspan: 1},
    {columnId: "standardNm", header: "規格", width: 80, align: "left",rowspan: 1},
    {columnId: "dtQty", header: "数量", width: 70, align: "right",rowspan: 1, numberPipe: '1.0-0'},
    {columnId: "redBlackFlgNm", header: "赤黒区分", width: 55, align: "left",rowspan: 2},
    {columnId: "reqStatusNm", header: "申請状態", width: 55, align: "left",rowspan: 2},
    {columnId: "orderPriceExc", header: "発注単価", width: 70, align: "right",rowspan: 1, numberPipe: '1.2-2'},
    {columnId: "posCostExc", header: "POS原価", width: 70, align: "right",rowspan: 1, numberPipe: '1.2-2'},
    {columnId: "sellPriceExc", header: "売価", width: 70, align: "right",rowspan: 1, numberPipe: '1.2-2'},
    {columnId: "reqDate", header: "廃棄申請日", width: 90, align: "center",rowspan: 2},
    {columnId: "approveDate", header: "廃棄承認日", width: 70, align: "center",rowspan: 2},
    {columnId: "cancelDate", header: "廃棄取消日", width: 70, align: "center",rowspan: 2},
    {columnId: "slipDtlComment", header: "詳細コメント", width: 150, align: "left",rowspan: 2},
    {columnId: "systemMsg", header: "システムメッセージ", width: 150, align: "left",rowspan: 2},
  ];

  public columnDefsItemsSecond: TableColumnDef[] = [
    {columnId: "productNm", header: "商品名称", width: 240, align: "left",rowspan: 1, colspan: 3},
    {columnId: "totalOrderPriceExc", header: "発注金額", width: 70, align: "right",rowspan: 1, colspan: 1, numberPipe: '1.2-2'},
    {columnId: "totalPosCostExc", header: "POS金額", width: 70, align: "right",rowspan: 1, colspan: 1, numberPipe: '1.2-2'},
    {columnId: "totalSellPriceExc", header: "売価金額", width: 70, align: "right",rowspan: 1, colspan: 1, numberPipe: '1.2-2'},
  ];

  access: ReqAccess | any = {
    ...this.commonService.loginUser,
    cmpnyCd: DTADetailDialogConstant.EMPTY_STRING,
    lang: DTADetailDialogConstant.EMPTY_STRING,
    storeCd: DTADetailDialogConstant.EMPTY_STRING,
  };

  public itemTableWidth: any;
  public itemsShow: Spmt10321GetDetailDto[] = [];
  public items: Spmt10321GetDetailDto[] = [];
  public itemRecordCount: number = 0;
  public slipNo: string;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10321Component>,
    public httpBasic: HttpBasicService,
    private message: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: {row: Spmt10321Dto},
    public commonService: CommonService) { }

  ngOnInit() {
    this.fnGetAccessInfo();
    this.calcTableWidth();
  }

  calcTableWidth() {
    this.itemTableWidth = {"width": "100%"};
  }

  getPageDto():ReqPageDto {
    return {
      pageNum: DTADetailDialogConstant.ZERO,
      dispNum: DTADetailDialogConstant.LIMIT_RECORD
    }
  }

  getDetailPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, DTADetailDialogConstant.MESSAGESEARCHING);
    const request: ReqSpmt10321GetDetail = {
      slipNo: this.data.row.slipNo,
      access: this.access,
      delFlg: this.data.row.delFlg,
      pageDto: this.getPageDto()
    }
    const subsc = this.httpBasic.getDetailSpmt10321(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpmt10321GetDetail) {
    this.commonService.closeSpinner();
    if(this.httpBasic.handleAppError(response)){
      return;
    }

    if (response.results.length == 0) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001])
      return;
    }

    if (response.results.length > DTADetailDialogConstant.MAXITEMS) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle,this.message.message[MI200003].replace(/%1/g,DTADetailDialogConstant.MAXITEMS));
    }

    response.results.slice(0,1000).forEach((item: Spmt10321GetDetailDto) => {
      item.systemMsg = (item.systemMsg === '1' && item.reqStatusNm == DTADetailDialogConstant.REQSTATUS_INIT) ? this.message.message[MI200010] : null;
      this.items.push(item)
    })
    this.itemRecordCount = this.items.length ?? 0;
    this.slipNo = this.items[0].slipNo;
    this.pageChanged();
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }

  fnGetAccessInfo() {
    let req: Req = {
      access: this.access
    };
    this.httpBasic.getUserInfo(req).subscribe((res) => {
      if (this.httpBasic.handleAppError(res)) return;
      if (res.rows.length > 0) {
        this.access.cmpnyCd = res.rows[0].mainCmpnyCd;
        this.access.storeCd = res.rows[0].mainStoreCd;
        this.access.sysModeCd = res.rows[0].sysModeCd;
        this.access.lang = res.rows[0].mainLang;
        this.getDetailPrc();
      }
    });
  }

  rowStyle(redBlackFlg: string){
    if(redBlackFlg == DTADetailDialogConstant.REDBLACKFLG_02) {
      return 'text-color-red';
    }
    return DTADetailDialogConstant.EMPTY_STRING;
  }
}
